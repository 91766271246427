import { createReducer } from 'utils/reduxHelpers';
import { Api, setAuthToken } from 'utils/connectors';
import { getLocalToken, removeLocalToken, saveLocalToken } from 'utils/tokenHelpers';
import { getImageUrl } from 'utils/appHelpers';
import { setUserAppPermissions, clearPermissions } from 'utils/permissionHelper';

const ON_AUTH_SATE = '@@auth/ON_AUTH_SATE';
const ON_LOGIN = '@@auth/ON_LOGIN';
const ON_LOGOUT = '@@auth/ON_LOGOUT';

const onAuthState = payload => ({ type: ON_AUTH_SATE, payload });
const onLogin = payload => ({ type: ON_LOGIN, payload });
const onLogout = () => ({ type: ON_LOGOUT });

const isAuthHandlers = {
  [ON_AUTH_SATE]: (state, action) => action.payload,
};

const accountHandlers = {
  [ON_LOGIN]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

const clearForLogout = dispatch => {
  dispatch(onAuthState(0));
  dispatch(onLogout());
  clearPermissions();
  removeLocalToken();
};

const getProfile = async () => {
  try {
    clearPermissions();
    return await Api.post('/auth/getprofile').then(({ data }) => {
      const profile = data && data.data ? data.data : false;
      if (profile) {
        profile.imageUrl = getImageUrl(profile.imageUrl);
        setUserAppPermissions(profile.userRole);
        return profile;
      } else throw new Error('User not found');
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const getAndUpdateProfile = () => async dispatch => {
  try {
    const res = await getProfile();
    await dispatch(onLogin(res));
  } catch (err) {
    clearForLogout(dispatch);
  }
};

export const getAuthState = () => async dispatch => {
  let token = getLocalToken();
  const url = new URL(window.location.href);
  const isKolAuth = url.pathname.includes('grant/kol');
  const kolToken = isKolAuth && url.searchParams.get('t');

  if (isKolAuth && kolToken) {
    token = kolToken;
    saveLocalToken(token);
  }

  if (token) {
    // set common headers
    setAuthToken(token, Api);

    try {
      const user = await getProfile();
      // Keep user in store
      await dispatch(onLogin(user));
      // Set Aut State 2 - is login state (Account)
      dispatch(onAuthState(2));
    } catch (err) {
      // Set Aut State 0 - is logout state (Activation)
      dispatch(onAuthState(0));
      // Set logout
      clearForLogout(dispatch);
    }
  }
  // Set Aut State 0 - is logout state (Activation)
  else dispatch(onAuthState(0));
};

export const loginUser = ({ data }) => async dispatch => {
  try {
    const token = data;

    saveLocalToken(token);
    setAuthToken(token, Api);
    // Set User Profile
    const user = await getProfile();
    await dispatch(onLogin(user));
    // Set Aut State 2 - is login state (Account)
    dispatch(onAuthState(2));
  } catch (err) {
    // Set Aut State 0 - is logout state (Activation)
    dispatch(onAuthState(0));
    // Set logout
    clearForLogout(dispatch);
  }
};

export const logout = () => async dispatch => {
  try {
    await Api.post('/admin/logout');
  } catch (err) {
    clearForLogout(dispatch);
  } finally {
    clearForLogout(dispatch);
  }
};

export const isAuthInitialState = null;
export const accountInitialState = null;

export const isAuthReducer = createReducer(isAuthInitialState, isAuthHandlers);
export const accountReducer = createReducer(accountInitialState, accountHandlers);
