import React, { useState } from 'react';
import LessonCaseRow from './LessonCaseRow';

const LessonCasesStepModal = ({ close, cases, activeCases, onSelectCases }) => {
  const [activeIds, setActiveIds] = useState(activeCases ? activeCases.map(i => i.caseId) : []);

  const onSelectItem = item => {
    const ids = [...activeIds];
    if (ids.includes(item.caseId)) {
      ids.splice(ids.indexOf(item.caseId), 1);
    } else {
      ids.push(item.caseId);
    }
    setActiveIds(ids);
  };

  const onCloseModal = () => {
    const items = cases.filter(i => activeIds.includes(i.caseId));
    const sorted = activeIds.map(i => items.find(c => c.caseId === i));
    onSelectCases(sorted);
    close();
  };

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Select Study Cases</h5>
        <p>Choose one or several cases that you want to be included in the lesson</p>
      </div>
      <div className='m-content flex-fill'>
        {cases &&
          cases.map((item, i) => {
            return (
              <label
                key={i}
                className='case-item pointer w-100 d-flex align-items-center justify-content-between'
                htmlFor={`radio${i}`}
              >
                <LessonCaseRow item={item} />
                <div className='d-flex'>
                  <input
                    type='checkbox'
                    id={`radio${i}`}
                    className='input-custom is-blue'
                    onChange={onSelectItem.bind(null, item)}
                    checked={activeIds.includes(item.caseId)}
                  />
                  <label className='m-0' htmlFor={`radio${i}`} />
                </div>
              </label>
            );
          })}
      </div>
      <p className='case-count text-right'>Select Cases: {activeIds.length}</p>
      <div className='m-footer text-center'>
        <button className='btn-blue btn px-4' onClick={onCloseModal}>
          Done
        </button>
      </div>
    </div>
  );
};

export default LessonCasesStepModal;
