/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import ClickArea from 'shared/components/ClickArea';
import { formatDate } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import { lessonTypesObj } from 'app/Main/configs';
import removeIcon from 'assets/bin.svg';

const headers = [
  {
    name: 'Name',
    className: 'col-2',
    key: 'name',
  },
  {
    name: 'Nickname',
    className: 'col-2',
    key: 'nickname',
  },
  {
    name: 'Type',
    className: 'col-1',
    key: 'contentType',
  },
  {
    name: 'Used',
    className: 'col-1',
    key: 'used',
  },
  {
    name: 'Section',
    className: 'col-2',
    key: 'episodesCount',
  },
  {
    name: 'Created',
    className: 'col-1',
    key: 'createdAt',
  },
  {
    name: 'Duration',
    className: 'col-1',
    key: 'duration',
  },
  {
    name: '',
    className: 'col-1',
    key: '',
  },
];

const LessonsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            const isVideo = item.contentType === 1;
            const isCases = item.contentType === 3;
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col-2 align-items-center'>{item.name || '-'}</div>
                <div className='d-flex col-2 align-items-center'>{item.nickname || '-'}</div>
                <div className='d-flex col-1 align-items-center'>
                  {lessonTypesObj[item.contentType]}
                </div>
                <div className='d-flex col-1 align-items-center'>{item.used || '-'}</div>
                <div className='d-flex col-2 align-items-center'>
                  {isVideo && <span>{item.episodesCount} Episodes</span>}
                  {isCases && <span>{item.caseCount} Cases</span>}
                  {!isVideo && !isCases && 1}
                </div>
                <div className='d-flex col-1 align-items-center px-1 pl-2'>
                  {formatDate(item.createdAt) || '-'}
                </div>
                <div className='d-flex col-1 align-items-center'>{item.duration}</div>
                <div className='p-2 justify-content-end w-100 d-flex'>
                  {isCases && (
                    <Link
                      className='more-btn small mr-2 font-weight-bold text-blue'
                      to={`/lesson/${item.id}/heatmaps`}
                    >
                      Heatmap
                    </Link>
                  )}
                  <Link
                    className='more-btn small mr-2 font-weight-bold text-blue'
                    to={`/lesson/${item.id}`}
                  >
                    Edit
                  </Link>
                  <button
                    onClick={onItemRemoveConfirm.bind(null, item)}
                    disabled={onItemRemoveDisabled}
                    type='button'
                    className='btn btn-sm btn-red list-remove-btn'
                  >
                    <img width='15' src={removeIcon} alt='icon' />
                  </button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LessonsLists;
