import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { getError } from 'utils/appHelpers';
import useDebounce from 'shared/hooks/useDebounce';
import SubscriptionModal from './components/ConfirmRequestModal';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';

const SubscriptionRequest = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const { filter } = match.params;

  const [fetch, setFetch] = useState(false);
  const [order, setOrder] = useState({ order: false, orderKey: 'createdAt' });
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getData = async () => {
    try {
      setFetch(true);
      const status = filter === 'all' ? null : filter;
      const params = {
        ...order,
        group: 1,
        page: page + 1,
        limit: 8,
        status,
        search: debouncedSearch || undefined,
      };
      const { data } = await Api.get(`/common/requests`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onSelectSubscription = (type, data) => {
    setModalType(type);
    setModalData(data);
  };

  const onModalClose = () => {
    setModalType(null);
    setModalData(null);
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [page, order, debouncedSearch, filter]);

  return (
    <div>
      <Header onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists
            fetch={fetch}
            users={data}
            order={order}
            changeOrder={setOrder}
            selectSubscription={onSelectSubscription}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      {modalType && modalData && (
        <SubscriptionModal
          data={modalData}
          type={modalType}
          onModalClose={onModalClose}
          onSuccess={getData}
        />
      )}
    </div>
  );
};

export default SubscriptionRequest;
