import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmRequestModal from '../../SubscriptionRequest/components/ConfirmRequestModal';

const SubActionButton = ({ show, className, onClick, disabled, loading, name }) => {
  if (!show) return null;
  return (
    <button className={`btn ${className}`} onClick={onClick} disabled={disabled}>
      {loading && <div className='spinner-border spinner-grow-sm mr-2' />}
      <span className='mr-1'>{name}</span>
    </button>
  );
};

const UserLicenseKeysModal = ({ close, onSuccess, user, entityId }) => {
  const [assignFetch, setAssignFetch] = useState(false);
  const [unAssignFetch, setUnassignFetch] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [modalState, setModalState] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const assignKey = async id => {
    try {
      setAssignFetch(id);
      const { data } = await Api.post(`/entity/licensekey/assign`, {
        originId: id,
        entityId: Number(entityId),
        userId: user.userId,
      });
      if (onSuccess) await onSuccess();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setAssignFetch(false);
    }
  };

  const unAssignKey = async id => {
    try {
      setUnassignFetch(id);
      const { data } = await Api.post(`/entity/licensekey/unassign`, {
        originId: id,
        entityId: Number(entityId),
        userId: user.userId,
      });
      if (onSuccess) await onSuccess();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUnassignFetch(false);
    }
  };

  const unAssignKeyConfirmation = id => {
    confirmAlert({
      message: `Unassign the key will block user's access to all courses that were accessible by this key. Are you sure your want proceed?`,
      buttons: [
        {
          label: 'Close',
        },
        {
          label: 'Continue',
          onClick: () => unAssignKey(id),
        },
      ],
    });
  };

  const getLicenses = async () => {
    try {
      const { data } = await Api.get(`/entity/licenses/unassigned/${entityId}`);
      setLicenses(data.data || []);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getLicenses();
    //eslint-disable-next-line
  }, []);

  if (!user) return null;

  const userKeys = user.licenseKeys && user.licenseKeys.map(item => item.key);
  const notAssigned = licenses && licenses.filter(item => !userKeys.includes(item.licenseKey));

  return (
    <>
      <div className='modal-box deactivate-modal d-flex flex-column'>
        <div className='align-items-start d-flex header justify-content-between'>
          <div>Manage License Keys</div>
          <div onClick={close} tabIndex='-1' role='button' className='mt-1 pointer'>
            <IconClose />
          </div>
        </div>

        <div className='content flex-fill overflow-auto'>
          <p className='mt-3 mb-2 weight-500 fz-16'>Assigned:</p>
          <div className='subs-plan-area'>
            {user.licenseKeys && user.licenseKeys.length ? (
              user.licenseKeys.map(item => {
                return (
                  <div
                    className='plan-box align-items-center d-flex justify-content-between'
                    key={item.id}
                  >
                    <div className='plan-name'>{item.key}</div>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex'>
                        <SubActionButton
                          name='UNASSIGN'
                          loading={unAssignFetch === item.id}
                          disabled={unAssignFetch}
                          onClick={unAssignKeyConfirmation.bind(null, item.id)}
                          className='text-danger'
                          show={true}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='plan-box'>No Active Keys</div>
            )}
          </div>
          <p className='mt-3 mb-2 weight-500 fz-16'>Not Assigned:</p>
          <div className='subs-plan-area'>
            {notAssigned && !!notAssigned.length ? (
              notAssigned.map(item => (
                <div
                  className='plan-box align-items-center d-flex justify-content-between'
                  key={item.licenseKey}
                >
                  <div className='plan-name'>{item.licenseKey}</div>
                  <div className='d-flex align-items-center'>
                    <SubActionButton
                      name='Assign'
                      loading={assignFetch === item.id}
                      disabled={assignFetch}
                      onClick={assignKey.bind(null, item.id)}
                      className='text-primary'
                      show={true}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className='plan-box'>No Keys</div>
            )}
          </div>
        </div>
        <div className='footer text-right'>
          <button className='btn mt-2 text-blue weight-600' onClick={close}>
            Done
          </button>
        </div>
      </div>
      {modalState.type && (
        <ConfirmRequestModal
          data={modalState.data}
          type={modalState.type}
          onModalClose={() => setModalState({})}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default UserLicenseKeysModal;
