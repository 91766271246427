import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/InputGroup';
import ButtonLine from 'shared/components/ButtonLine';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import AuthBlock from '../components/AuthBlock';
import { IconPassword } from 'shared/components/Icons';
import Loading from 'shared/components/Loading';

const ThankYouBlock = ({ history }) => {
  return (
    <div className='auth-thanks-block'>
      <h1 className='title'>Thank You</h1>
      <ButtonLine className='mt-3' type='button' onClick={() => history.push('/login')}>
        next
      </ButtonLine>
    </div>
  );
};

const Reset = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [validCode, setValidCode] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [pass, setPass] = useState({
    password: '',
    rpassword: '',
  });

  const authOptions = {
    title: 'New password',
    subTitle: 'Please enter new password.',
    links: { signup: true },
  };

  const passwordMatch = pass.password === pass.rpassword;

  const checkResetCode = useCallback(async () => {
    const code = match.params.code;
    try {
      await Api.get(`/admin/check/${code}`);
      setValidCode(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/login');
    }
  }, [enqueueSnackbar, history, match.params.code]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...pass };
    tempAuth[name] = value;
    setPass(tempAuth);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const body = {
        resettoken: match.params.code,
        password: pass.password,
      };
      await Api.post(`/admin/reset`, body);
      setSuccess(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    checkResetCode();
  }, [checkResetCode]);

  if (!validCode) return <Loading className='mt-5' />;

  return (
    <AuthBlock {...authOptions} successBlock={success ? <ThankYouBlock history={history} /> : null}>
      <form onSubmit={handleSubmit} className='mt-5'>
        <InputGroup
          type='password'
          name='password'
          onChange={handleChange}
          className='mb-4'
          placeholder='Password'
          value={pass.password}
          icon={<IconPassword />}
          required
        />
        <InputGroup
          type='password'
          name='rpassword'
          onChange={handleChange}
          className='mb-4'
          placeholder='Confirm password'
          value={pass.rpassword}
          icon={<IconPassword />}
          required
        />
        {error && <div className='error-text'>{error}</div>}
        {!passwordMatch && pass.rpassword && <div className='error-text'>Passwords mismatch</div>}
        <ButtonLine type='submit' disabled={fetch || !passwordMatch}>
          Done
        </ButtonLine>
      </form>
    </AuthBlock>
  );
};

export default Reset;
