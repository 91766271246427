import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ReactModal from 'react-modal';
import IconCases from 'assets/course/select_lessons.svg';
import CourseLessonsStepModal from './CourseLessonsStepModal';
import { getError, reorder } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';
import CourseLessonsRow from './CourseLessonsRow';

const CourseLessonsStep = ({ data, setData, isInUse }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [lessonsModal, setLessonsModal] = useState(false);
  const [lessons, setLessons] = useState();

  const getAuthors = async () => {
    try {
      const { data } = await Api.get('/wizard/lessons');
      setLessons(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSelectLessons = items => {
    setData({ ...data, activeLessons: items });
  };

  const onItemRemove = index => {
    const tempData = data.activeLessons.splice(index, 1);
    setData({ ...data, activeLessons: data.activeLessons.splice(tempData) });
  };

  const onDragEnd = e => {
    if (!e.destination) return;
    const activeLessons = reorder(data.activeLessons, e.source.index, e.destination.index);
    setData({ ...data, activeLessons });
  };

  useEffect(() => {
    getAuthors();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='px-2'>
        <h6 className='color-blue mb-3'>Manage Lessons</h6>
        {!isInUse && (
          <label
            className='col-7 upload-wizard-block pointer'
            onClick={() => setLessonsModal(true)}
          >
            <div className='d-flex flex-column align-items-center'>
              <img src={IconCases} alt='icon' className='mb-3' />
              Select Lessons
            </div>
          </label>
        )}
        <div className='col-8 p-0'>
          <DragList
            onDragEnd={onDragEnd}
            items={data.activeLessons}
            className='mt-3 mb-5'
            disabled={isInUse}
          >
            {(item, index) => {
              return (
                <DragItem
                  key={index}
                  disabled={isInUse}
                  className='case-item lesson-item with-drag pr-0'
                  onItemRemove={onItemRemove.bind(null, index)}
                  onItemRemoveDisabled={isInUse}
                >
                  <CourseLessonsRow item={item} />
                </DragItem>
              );
            }}
          </DragList>
        </div>
      </div>
      <ReactModal isOpen={lessonsModal} className='custom-modal'>
        <CourseLessonsStepModal
          lessons={lessons}
          activeLessons={data.activeLessons}
          onSelectLessons={onSelectLessons}
          close={() => setLessonsModal(false)}
        />
      </ReactModal>
    </>
  );
};

export default CourseLessonsStep;
