import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Modal from 'react-modal';
import { getError, formatDate } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import placeholder from 'assets/video.jpg';

const ConfirmRequestModal = ({ data, type, onModalClose, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fecth, setFetch] = useState(false);
  const isAccept = type === 'accept';

  const handleModalSubmit = async () => {
    try {
      setFetch(true);
      const path = isAccept ? '/subscription/approve' : '/subscription/decline';
      const body = { id: data.userSubscriptionId };
      await Api.post(path, body);
      if (onSuccess) onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
      onModalClose();
    }
  };

  return (
    <Modal
      className='modal-default subscription-modal flex-column d-flex'
      isOpen={true}
      onRequestClose={onModalClose}
      contentLabel='Connection and device test'
    >
      <button className='button-times' onClick={onModalClose} />
      <img
        className='subscription-image'
        src={data.subscription.imageUrl || placeholder}
        alt={data.subscription.title}
      />
      <h5 className='my-3'>{data.subscription.title}</h5>
      {data.learner && (
        <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
          <span>User</span>
          <span>
            {data.learner.firstName} {data.learner.lastName}
          </span>
        </div>
      )}
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Date</span>
        <span>{formatDate(data.createdAt)}</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Code</span>
        <span>{data.uniqueKey || '-'}</span>
      </div>
      <div className='d-flex justify-content-between mt-3'>
        <button className='btn-white btn-block btn mr-3' disabled={fecth} onClick={onModalClose}>
          Cancel
        </button>
        <button
          className='btn-white btn-block btn mt-0 ml-3'
          disabled={fecth}
          onClick={handleModalSubmit}
        >
          {isAccept ? 'Accept' : 'Decline'}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmRequestModal;
