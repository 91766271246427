import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

const EntitiesNew = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState({
    name: '',
    address: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      await Api.post(`/entity/addentity`, form);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push('/entities');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/entities'>Entities</Link>
          <span className='mx-2'>/</span>
          <span>Add New Entity</span>
        </div>
      </div>
      <form className='py-3 has-header' onSubmit={onFormSubmit}>
        <div className='col-7 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Name:</label>
              </div>
              <EditingInput
                type='text'
                name='name'
                value={form.name}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Address:</label>
              </div>
              <EditingInput
                type='text'
                name='address'
                value={form.address}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Address'
                required
                editing={true}
              />
            </li>
          </ul>
          <div className='text-right'>
            <button type='submit' disabled={fetching} className='btn btn-primary px-4 fz-12'>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EntitiesNew;
