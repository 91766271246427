import React from 'react';

const UploadProgress = ({ progress }) => {
  if (!progress) return null;
  return (
    <div className='w-75 mb-2'>
      <div className='progress'>
        <div className={`progress-bar`} style={{ width: `${progress || 0}%` }} />
      </div>
    </div>
  );
};

export default UploadProgress;
