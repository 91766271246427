import packageJson from './../../package.json';

const generic = {
  production: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.hologicace.com/',
    learner_domain: 'https://www.hologicace.com',
  },
  stage: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.hologicace.com/',
    learner_domain: 'https://site-test.hologicace.com',
  },
  development: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'dev.fordemo.website/',
    learner_domain: 'https://site-dev.fordemo.website',
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST = {
  API: {
    VERSION: packageJson.version,
    URL: `${main.url_protocol}://${main.api_domain}`,
    WS: `${main.ws_protocol}://${main.api_domain}`,
    LEARNER_DOMAIN: main.learner_domain,
    API_PREFIX: '',
    AUTH_HEADER: 'Authentication',
    AUTH_PREFIX: '',
  },
};
