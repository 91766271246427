import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { IconClose } from 'shared/components/Icons';
import removeIcon from 'assets/bin.svg';

const SectionsModal = ({ close, sections, setForm }) => {
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setForm(form => {
      const sections = [...form.sections];
      sections[index][name] = value;
      return { ...form, sections };
    });
  };

  const onAddSection = () => {
    setForm(form => {
      const sections = [...form.sections, { title: '', descriptions: '' }];
      return { ...form, sections };
    });
  };

  const onSectionRemove = index => {
    setForm(form => {
      const sections = [...form.sections];
      sections.splice(index, 1);
      return { ...form, sections };
    });
  };

  return (
    <div className='modal-box deactivate-modal d-flex flex-column'>
      <div className='align-items-start d-flex header justify-content-between'>
        <div>Certificate Content</div>
        <div onClick={close} tabIndex='-1' role='button' className='mt-1 pointer'>
          <IconClose />
        </div>
      </div>
      <div className='py-3 px-3 row overflow-auto'>
        {sections &&
          sections.map((item, index) => {
            const isFirst = index === 0;
            const isLast = index + 1 === sections.length;
            return (
              <div className='user-creation-form mb-3 w-100' key={index}>
                <ul className='p-0 mb-2'>
                  <li className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Name:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='title'
                      value={item.title}
                      onChange={handleChange.bind(null, index)}
                      className='p-0'
                      placeholder='Enter Title:'
                      required
                      editing={true}
                    />
                  </li>
                  <li className='d-flex'>
                    <div className='col-4 pl-0 pt-1'>
                      <label className='m-0'>Section Description:</label>
                    </div>
                    <EditingTextArea
                      type='text'
                      name='descriptions'
                      value={item.descriptions}
                      onChange={handleChange.bind(null, index)}
                      className='p-0 mb-1'
                      placeholder='Enter Description'
                      editing={true}
                    />
                  </li>
                </ul>
                <div className='d-flex justify-content-end'>
                  {isLast && (
                    <button
                      type='button'
                      className='btn btn-sm btn-blue mr-2 font-weight-bold list-remove-btn'
                      onClick={onAddSection}
                    >
                      +
                    </button>
                  )}
                  <button
                    onClick={onSectionRemove.bind(null, index)}
                    disabled={isFirst}
                    type='button'
                    className='btn btn-sm btn-red list-remove-btn'
                  >
                    <img width='15' src={removeIcon} alt='icon' />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      <div className='footer text-right'>
        <button className='btn mt-2 text-blue weight-600' onClick={close}>
          OK
        </button>
      </div>
    </div>
  );
};

export default SectionsModal;
