import React from 'react';
import ProgressBar from './ProgressBar';

const PlanProgress = ({ data: { name, start_date, end_date, progress } }) => (
  <>
    <p className='weight-900'>{name}</p>
    <div className='d-flex align-items-end'>
      <div className='col p-0'>
        <div className='d-flex justify-content-between'>
          <span className='text-date'>{start_date ? `Start ${start_date}` : 'Not Started'}</span>
          <span className='text-date'>{progress >= 100 && end_date && `End ${end_date}`}</span>
        </div>
        <div className='d-flex align-items-center'>
          <div className='col p-0'>
            <ProgressBar progress={progress} isHeader />
          </div>
        </div>
      </div>
      <span className='weight-900 ml-2'>{progress}%</span>
    </div>
  </>
);

export default PlanProgress;
