import React, { useEffect, useState, cloneElement } from 'react';

const listenToOutsideClick = (name, dispatch) => {
  const header = document.querySelector(`.drop-name-${name} .app-dropdown-header`);
  const body = document.querySelector(`.drop-name-${name} .app-dropdown-body`);
  document.addEventListener('click', e => {
    const clicked = el => el.contains(e.target);
    if (!clicked(header) && !clicked(body)) dispatch(false);
  });
};

const DropdownHeader = ({ children, onClick, className }) => {
  return (
    <div className={`app-dropdown-header ${className || ''}`} onClick={onClick} role='presentation'>
      {children}
    </div>
  );
};
const DropdownBody = ({ children }) => {
  return <div className='app-dropdown-body d-flex flex-column'>{children}</div>;
};

const DropDown = ({ children, className = '', name = 'default' }) => {
  const [open, toggleDropdown] = useState(false);

  useEffect(() => {
    if (open) listenToOutsideClick(name, toggleDropdown);
    //eslint-disable-next-line
  }, [open]);

  return (
    <div className={`app-dropdown drop-name-${name} ${className} ${open ? 'active' : ''}`}>
      {cloneElement(children[0], { onClick: () => toggleDropdown(!open) })}
      {open && children[1]}
    </div>
  );
};

export { DropDown, DropdownHeader, DropdownBody };
