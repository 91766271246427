import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { getBase64, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { IconEditButton } from 'shared/components/Icons';
import certificateWrapperImg from 'assets/certificate-wrapper.png';
import certificateWrapperImgFDA from 'assets/certificate-wrapper-fda.png';
import certificateFooterImg from 'assets/certificate-footer.png';
import certificateFooterImgFDA from 'assets/certificate-footer-fda.png';
import certificateLogoImg from 'assets/certificate-logo.png';
import noImage from 'assets/noimage.png';
import SectionsModal from './components/SectionsModal';
import ReactModal from 'react-modal';
import { useEffect } from 'react';
import Loading from 'shared/components/Loading';

const CertificatesNew = ({ history, match }) => {
  const { id } = match.params;
  const isEdit = !!match.params.id;
  const [sectionModal, setSectionModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState();
  const [form, setForm] = useState({
    name: '',
    sections: [{ title: '', descriptions: '' }],
  });

  const getCertificate = async () => {
    try {
      const { data } = await Api.get(`/wizard/certificate/${id}`);
      const body = {};
      body.name = data.data.name;
      body.sections = data.data.sections.map(item => {
        const descs = item.items.map(i => i.description);
        item.descriptions = descs.join('\n');
        return item;
      });
      body.originId = data.data.id;
      setForm(body);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const body = { ...form };
      body.sections = body.sections.map(item => {
        item.descriptions = item.descriptions.split(/(?:\r\n|\r|\n)/g);
        return item;
      });
      const endpoint = isEdit ? '/wizard/certificate/update' : '/wizard/certificate/init';
      await Api.post(endpoint, body);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push('/certificates');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const handleImageSelect = file => {
    if (!file) return null;
    getBase64(file, result => {
      const tempForm = { ...form };
      tempForm.stampBase64Image = result;
      setForm(tempForm);
    });
  };

  useEffect(() => {
    if (isEdit) getCertificate();
  }, []);

  const logo = form.stampBase64Image || (data && data.stampImageUrl);
  const btnDisabled = !logo || !form.name || !form.sections || !form.sections.length;
  const isFDA = isEdit && data && data.template === 'fda';

  if (isEdit && !data) return <Loading />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/certificates'>All Certificates</Link>
          <span className='mx-2'>/</span>
          <span>{isEdit ? 'Update' : 'Create New'} Certificate</span>
        </div>
      </div>
      <form className='py-3 has-header' onSubmit={onFormSubmit}>
        <div className='col-9 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Name:</label>
              </div>
              <EditingInput
                type='text'
                name='name'
                value={form.name}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={true}
              />
            </li>
          </ul>
          <div className='d-flex justify-content-end'>
            <div className='certificate-form'>
              <img src={isFDA ? certificateWrapperImgFDA : certificateWrapperImg} alt='wrapper' />
              <div className='certificate-content d-flex align-items-start'>
                <button type='button' className='btn p-0' onClick={() => setSectionModal(true)}>
                  <IconEditButton />
                </button>
                <div className='d-flex flex-column'>
                  {form.sections.map((item, i) => {
                    if (!item.title) return null;
                    return (
                      <div key={i} className='ml-2 my-1'>
                        <b>{item.title}:</b>
                        <span className='ml-1'>{item.descriptions}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <img src={isFDA ? certificateFooterImgFDA : certificateFooterImg} alt='wrapper' />
              <div className='certificate-logo-area d-flex align-items-center justify-content-between'>
                <div>
                  <img
                    src={logo || noImage}
                    alt='img'
                    width='70'
                    className={`${!logo ? 'border rounded-15' : ''}`}
                  />
                  <input
                    id='file'
                    name='file'
                    type='file'
                    className='flex-fill hide'
                    onChange={e => handleImageSelect(e.target.files[0])}
                  />
                  <label htmlFor='file' className='btn p-0 ml-2 pointer'>
                    <IconEditButton />
                  </label>
                </div>
                <div>
                  <img height='33' src={certificateLogoImg} alt='logo' />
                </div>
              </div>
            </div>
          </div>
          <div className='text-center certificate-btn-area'>
            <button type='submit' disabled={btnDisabled || fetching} className='btn btn-blue px-4'>
              Save
            </button>
          </div>
        </div>
      </form>
      <ReactModal isOpen={sectionModal} className='custom-modal'>
        <SectionsModal
          close={() => setSectionModal(false)}
          sections={form.sections}
          setForm={setForm}
        />
      </ReactModal>
    </div>
  );
};

export default CertificatesNew;
