import React from 'react';
import { formatDate } from 'utils/appHelpers';

const InfoIcon = ({ name, value, className }) => (
  <p className={`m-0 ${className || ''}`}>
    {name}: <b>{value}</b>
  </p>
);

const SubsCoursesRow = ({ item }) => {
  return (
    <div className='d-flex align-items-center subs-course-row'>
      <div className={`image-container ${!item.imageUrl ? 'no-image' : ''}`}>
        <img src={item.imageUrl} alt='img' />
      </div>
      <div>
        <div className='case-item-title d-flex align-items-center mb-1'>{item.title}</div>
        <div className='course-desc mb-1'>{item.info}</div>
        <div className='d-flex'>
          <InfoIcon name='Date Added' value={formatDate(item.createdAt) || '-'} />
          <InfoIcon name='Duration' className='ml-2' value={`${item.duration || 0} Hours`} />
        </div>
      </div>
    </div>
  );
};

export default SubsCoursesRow;
