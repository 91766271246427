import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconNotification, IconArrowRight, IconArrowLeft } from 'shared/components/Icons';
import { IconSearch } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { withRouter } from 'react-router';
import ReactPaginate from 'react-paginate';
import NotificationItem from './NotificationItem';
import useDebounce from 'shared/hooks/useDebounce';
import { getError } from 'utils/appHelpers';
import ClickArea from 'shared/components/ClickArea';
import moment from 'moment';
import { getLocalToken } from 'utils/tokenHelpers';
import { HOST } from 'configs';
import kolIcon from 'assets/kol_icon.svg';
import { hasAccess } from 'utils/permissionHelper';

const groupByDate = data => {
  const obj = [];
  data.forEach(item => {
    const date = moment(item.dateTime).format('MM/DD/YYYY');
    if (!obj[date]) {
      obj[date] = {
        date: item.dateTime,
        data: [item],
      };
    } else {
      obj[date].data.push(item);
    }
  });

  return Object.values(obj);
};

const Notifications = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [unread, setUnread] = useState();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(0);

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 500);

  const getNotifications = async () => {
    try {
      const limit = 7;
      const status = filter === 1 ? 1 : undefined;
      const params = { page: page + 1, limit, search: debouncedSearch, showNew: status };
      const res = await Api.get('/common/getnotification', { params });
      const { count, notifications } = res.data.data;
      setUnread(count);
      setPageCount(Math.ceil(notifications.count / limit));
      setData(groupByDate(notifications.results));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleFilterChange = value => {
    setFilter(value);
    setPage(0);
  };

  const openNotifications = () => {
    setOpen(item => !item);
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const onReadNotification = async id => {
    try {
      await Api.get(`/common/notificationmarkasread/${id}`);
      await getNotifications();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const openLearner = () => {
    const token = getLocalToken();
    window.open(`${HOST.API.LEARNER_DOMAIN}/grant/kol?t=${token}`, '_blank');
  };

  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line
  }, [location.pathname, page, debouncedSearch, filter]);

  return (
    <div className={`notification-block ${open ? 'opened' : ''}`}>
      <div className='d-flex align-items-center justify-content-end'>
        {hasAccess('kol_user') && (
          <button className='btn p-1 col-switch-btn' onClick={openLearner}>
            <img src={kolIcon} alt='col' />
          </button>
        )}
        <div
          className='notifcation-header pointer'
          onClick={openNotifications}
          role='button'
          tabIndex='-1'
        >
          <button className='position-relative btn p-1 col-switch-btn'>
            <IconNotification />
            {!!unread && <span className='notif-count'>{unread}</span>}
          </button>
        </div>
      </div>

      {open && (
        <div className='notification-body'>
          <div className='search-block icon-in'>
            <input
              type='text'
              className='form-control'
              onChange={({ target }) => setSearch(target.value)}
              placeholder='Search'
            />
            <IconSearch />
          </div>
          <div className='d-flex notifcation-filter mt-3'>
            <ClickArea
              onClick={() => handleFilterChange(0)}
              className={`px-2 ${filter === 0 ? 'active' : ''}`}
            >
              All
            </ClickArea>
            <ClickArea
              onClick={() => handleFilterChange(1)}
              className={`px-2 ${filter === 1 ? 'active' : ''}`}
            >
              Unread
            </ClickArea>
          </div>
          <div className='notifications'>
            {data.map((item, index) => {
              return (
                <div key={index + item.date}>
                  <div className='my-3'>
                    <b>{moment(item.date).format('MM/DD/YYYY')}</b>
                  </div>
                  {item.data.map((item, index) => (
                    <NotificationItem key={index} data={item} onRead={onReadNotification} />
                  ))}
                </div>
              );
            })}
          </div>
          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={<IconArrowLeft />}
              nextLabel={<IconArrowRight />}
              breakLabel={'...'}
              forcePage={page}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={onPageChange}
              containerClassName={`pagination show-arrow`}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(Notifications);
