import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import CasesHeader from './components/CasesHeader';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import CasesLists from './components/CasesLists';
import useDebounce from 'shared/hooks/useDebounce';
import { confirmAlert } from 'react-confirm-alert';

const Cases = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cases, setCases] = useState([]);
  const [order, setOrder] = useState({ order: true, orderKey: 'title' });
  const [dropFilters, setDropFilters] = useState({ dicomType: '', scanType: '', used: '' });
  const [fetch, setFetch] = useState(0);
  const [removeFetch, setRemoveFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getCases = useCallback(async () => {
    try {
      setFetch(true);
      const params = {
        ...order,
        page: page + 1,
        limit: 8,
        scanId: debouncedSearch,
        ...dropFilters,
      };
      const { data } = await Api.get(`/cases/allcases`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setCases(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
    //eslint-disable-next-line
  }, [enqueueSnackbar, order, page, dropFilters, debouncedSearch]);

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/cases/case/${item.scanId}`);
      await getCases();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.used > 0) {
      confirmAlert({
        message: 'The scan is used in lesson(s) Detach it from lesson before removing.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Case Removal',
      message: 'Are you sure you want to remove the case and all related DICOM files?',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    getCases();
    //eslint-disable-next-line
  }, [getCases, page, order, dropFilters, debouncedSearch]);

  return (
    <div>
      <CasesHeader
        dropFilters={dropFilters}
        setDropFilters={setDropFilters}
        onSearch={text => setSearch(text)}
      />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <CasesLists
            list={cases}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Cases;
