/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { DropDown, DropdownHeader, DropdownBody } from 'shared/components/DropDown';

const FilterDropDown = ({ title, data, activeItemId, onItemClick, className, name }) => {
  const activeItem = activeItemId && data.find(i => i.id === activeItemId);

  return (
    <DropDown
      name={name || title}
      className={`export-drop-down d-flex align-items-center ${
        activeItem ? 'selected' : ''
      } ${className || ''}`}
    >
      <DropdownHeader className='align-items-center d-flex mr-3'>
        <span className='mr-2 d-flex'>{activeItem ? activeItem.name : title}</span>
      </DropdownHeader>
      <DropdownBody>
        <ul>
          {activeItem && (
            <li className='drop-item' onClick={onItemClick.bind(null, '')}>
              All
            </li>
          )}
          {data.map(
            item =>
              item.id !== activeItemId && (
                <li className='drop-item' key={item.id} onClick={onItemClick.bind(null, item.id)}>
                  {item.name}
                </li>
              ),
          )}
        </ul>
      </DropdownBody>
    </DropDown>
  );
};

export default FilterDropDown;
