import React from 'react';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import FilterDropDown from 'shared/components/FilterDropDown';
import { IconSearch } from 'shared/components/Icons';

const filterUsage = [{ name: 'Used', id: 1 }, { name: 'Unused', id: 2 }];
const dicomTypes = [
  { name: '2D', id: 'i2d' },
  { name: '3DQ', id: '3dq' },
  { name: 'Tomo', id: 'tomo' },
];
const scanTypes = [
  { name: 'LCC', id: 'LCC' },
  { name: 'RCC', id: 'RCC' },
  { name: 'LMLO', id: 'LMLO' },
  { name: 'RMLO', id: 'RMLO' },
];

const CasesHeader = ({ dropFilters, setDropFilters, onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('cases_create') && (
          <Link className='btn btn-invite' to='/cases/new'>
            + Upload New DICOM
          </Link>
        )}
        <FilterDropDown
          className='ml-4 filter-drop-down'
          title='Usage'
          data={filterUsage}
          activeItemId={dropFilters.used}
          onItemClick={id => setDropFilters({ ...dropFilters, used: id })}
        />
        <FilterDropDown
          className='ml-4 filter-drop-down'
          title='DICOM Type'
          name='Dicom'
          data={dicomTypes}
          activeItemId={dropFilters.dicomType}
          onItemClick={id => setDropFilters({ ...dropFilters, dicomType: id })}
        />
        <FilterDropDown
          className='ml-4 filter-drop-down'
          title='Scan Type'
          name='Scan'
          data={scanTypes}
          activeItemId={dropFilters.scanType}
          onItemClick={id => setDropFilters({ ...dropFilters, scanType: id })}
        />
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder='Scan ID'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default CasesHeader;
