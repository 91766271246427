import { combineReducers } from 'redux';
import { isAuthReducer, accountReducer } from 'app/Auth/actions';

// Load reducers

const rootReducer = combineReducers({
  isAuthenticated: isAuthReducer,
  account: accountReducer,
});

export default rootReducer;
