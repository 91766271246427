import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export let userPermissions = {};

export const permissions = {
  1: [
    'home_view',
    'subscriptions_view',
    'subscriptions_join_request_view',
    'subscriptions_list_view',
    'subscription_view',
    'subscription_create',
    'subscription_request_confirm',
    'courses_statistics_view____hided',
    'courses_create',
    'courses_list_view',
    'course_view',
    'users_create',
    'users_edit',
    'users_list_view',
    'user_view',
    'user_progress_view',
    'user_test_history_view',
    'admin_create',
    'admins_list_view',
    'admin_view',
    'authors_list_view',
    'author_view',
    'author_create',
    'statistics_view____hided',
    'icons_view',
    'notification_read',
    'support_tickets_view',
    'support_emails_view',
    'reports_import',
    'reports_export',
    'lessons_create',
    'lessons_list_view',
    'cases_list_view',
    'cases_create',
    'license_create',
    'license_list_view',
    'entity_create',
    'entity_list_view',
    'entity_user_create',
    'entity_user_list_view',
    'publish_subscription',
    'certificates_list_view',
    'certificates_create',
    'system_logs_view',
  ],
  2: [
    'home_view',
    'subscriptions_view',
    'subscriptions_join_request_view',
    'subscriptions_list_view',
    'subscription_view',
    'subscription_create',
    'subscription_request_confirm',
    'courses_statistics_view____hided',
    'courses_create',
    'courses_list_view',
    'course_view',
    'users_create',
    'users_edit',
    'users_list_view',
    'user_view',
    'user_progress_view',
    'user_test_history_view',
    'admin_create',
    'admins_list_view',
    'admin_view',
    'authors_list_view',
    'author_view',
    'author_create',
    'statistics_view____hided',
    'notification_read',
    'support_tickets_view',
    'support_emails_view',
    'reports_import',
    'reports_export',
    'lessons_create',
    'lessons_list_view',
    'cases_list_view',
    'cases_create',
    'license_create',
    'license_list_view',
    'entity_create',
    'entity_list_view',
    'entity_user_create',
    'entity_user_list_view',
    'publish_subscription',
    'certificates_list_view',
    'certificates_create',
    'system_logs_view',
  ],
  3: [
    'home_view',
    'subscriptions_view',
    'subscriptions_join_request_view',
    'subscriptions_list_view',
    'subscription_view',
    'courses_statistics_view____hided',
    'courses_list_view',
    'course_view',
    'users_list_view',
    'user_view',
    'users_create',
    'user_progress_view',
    'user_test_history_view',
    'admins_list_view',
    'admin_view',
    'authors_list_view',
    'author_view',
    'statistics_view____hided',
    'support_tickets_view',
    'notification_read',
    'support_emails_view',
    'reports_import',
    'reports_export',
    'lessons_list_view',
    'cases_list_view',
    'cases_create',
    'license_create',
    'license_list_view',
    'entity_create',
    'entity_list_view',
    'entity_user_create',
    'entity_user_list_view',
    'publish_subscription',
    'system_logs_view',
  ],
  4: [
    'users_list_view',
    'user_view',
    'user_progress_view',
    'user_test_history_view',
    'entity_user_list_view',
    'entity_user_create',
    'entity_manager',
    'notification_read',
    'license_create',
    'license_list_view',
  ],
  6: [
    'kol_user',
    'home_view',
    'subscriptions_view',
    'subscriptions_list_view',
    'subscription_view',
    'subscription_create',
    'courses_create',
    'courses_list_view',
    'course_view',
    'lessons_create',
    'lessons_list_view',
    'cases_list_view',
    'cases_create',
  ],
};

export const setUserAppPermissions = role => {
  permissions[role].forEach(item => {
    userPermissions[item] = true;
  });
};

export const hasAccess = name => {
  return !!userPermissions[name];
};

export const PRoute = props => {
  if (props.access && !hasAccess(props.access)) return <Redirect to='/' />;
  return <Route {...props} />;
};

export const clearPermissions = () => {
  userPermissions = {};
};
