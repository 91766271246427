/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSnackbar } from 'notistack';
import { DropDown, DropdownHeader, DropdownBody } from 'shared/components/DropDown';
import { getError, saveFile } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import moment from 'moment';

const ExportList = ({ data, type }) => {
  const { enqueueSnackbar } = useSnackbar();

  const endpoints = {
    users: 'common/exportusers',
    courses: 'courses/exportcourses',
  };

  const fileNames = {
    users: {
      1: 'completed_users_date.pdf',
      2: 'all_users_date.pdf',
      3: 'all_users_date.csv',
    },
    courses: {
      1: 'course_users_date.pdf',
      2: 'all_courses_date.pdf',
      3: 'all_courses_date.csv',
    },
  };

  const exportListItemClick = async item => {
    try {
      const { data } = await Api.get(`${endpoints[type]}/${item.id}`, {
        responseType: 'blob',
        timeout: 30000,
      });
      const date = moment().format('YYYYMMDDHHmmss');
      const fileName = fileNames[type][item.id].replace('date', date);
      await saveFile(data, fileName);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <DropDown className='export-drop-down d-flex align-items-center'>
      <DropdownHeader className='align-items-center d-flex mr-3'>
        <span className='mr-2 d-flex'>Export</span>
      </DropdownHeader>
      <DropdownBody>
        <ul>
          {data.map(item => (
            <li className='drop-item' key={item.id} onClick={exportListItemClick.bind(this, item)}>
              {item.name}
            </li>
          ))}
        </ul>
      </DropdownBody>
    </DropDown>
  );
};

export default ExportList;
