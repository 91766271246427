import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { Button } from '@material-ui/core';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingInput from 'shared/components/editingForms/EditingInput';
import removeIcon from 'assets/bin.svg';
import { hasAccess } from 'utils/permissionHelper';
import { useSelector } from 'react-redux';

const LicensesNew = ({ history, match }) => {
  const { entityId } = match.params;
  const account = useSelector(state => state.account);
  const hasEntityAccess = hasAccess('entity_list_view');
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [entities, setEntities] = useState([]);
  const [subs, setSubs] = useState([]);

  const [license, setLicense] = useState({
    subscriptionId: '',
    entityId: account.entity ? account.entity.id : entityId ? entityId : undefined,
    // maxUsageCount: 1,
    codes: [''],
  });

  const getSelectDatas = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      if (hasEntityAccess) {
        const entities = await Api.get(`/entity/entities`, { params });
        setEntities(entities.data.data.results);
      }
      const subscriptions = await Api.get(`/entity/subscriptions`, { params });
      setSubs(subscriptions.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLicense({ ...license, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      await Api.post(`/entity/licensekey/add`, license);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.goBack ? history.goBack() : history.push('/licenses/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onChangeKey = (index, { target }) => {
    const tempData = { ...license };
    tempData.codes[index] = target.value;
    setLicense(tempData);
  };

  const onRemoveKey = index => {
    const tempData = { ...license };
    tempData.codes.splice(index, 1);
    setLicense(tempData);
  };

  const onAddKey = () => {
    const tempData = { ...license };
    tempData.codes.push('');
    setLicense(tempData);
  };

  useEffect(() => {
    getSelectDatas();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/licenses/all'>License Keys</Link>
          <span className='mx-2'>/</span>
          <span>Add New Key</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <form onSubmit={handleSubmit} className='col-8 p-0 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Subscription Plan:</label>
              </div>
              <EditingSelect
                name='subscriptionId'
                onChange={handleChange}
                value={license.subscriptionId}
                items={subs}
                useProperty='id'
                displayProperty='title'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Entity:</label>
              </div>
              <EditingSelect
                name='entityId'
                onChange={handleChange}
                value={license.entityId || ''}
                items={entities}
                useProperty='id'
                displayProperty='name'
                editing={hasEntityAccess}
                forceValue={!hasEntityAccess && account.entity ? account.entity.name : false}
              />
            </li>
            {/* <li className='list-default'>
              <div className='d-flex align-items-center'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Number Of Assignments:</label>
                </div>
                <EditingInput
                  type='number'
                  name='maxUsageCount'
                  value={license.maxUsageCount}
                  onChange={handleChange}
                  placeholder='Enter Max Usage Count'
                  required
                  editing={hasEntityAccess}
                />
              </div>
            </li> */}
          </ul>
          <h5 className='p-3 color-blue'>License Keys</h5>
          <ul className='p-0 mb-0'>
            {license.codes.map((item, index) => {
              return (
                <div className='d-flex drag-item' key={index}>
                  <li className='d-flex align-items-center w-100'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>License Key {index + 1}:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='item'
                      value={item}
                      onChange={onChangeKey.bind(null, index)}
                      className='p-0'
                      placeholder='Enter Key'
                      required
                      editing={true}
                    />
                  </li>
                  <button
                    onClick={onRemoveKey.bind(null, index)}
                    disabled={license.codes.length < 2}
                    type='button'
                    className='btn btn-remove ml-2'
                  >
                    <img src={removeIcon} alt='icon' />
                  </button>
                </div>
              );
            })}
          </ul>
          <div className='d-flex justify-content-end text-right add-new-items-btns mt-0 mr-2 pr-5'>
            <button type='button' className='btn btn btn-outline-blue' onClick={onAddKey}>
              Add key +
            </button>
          </div>
          <div className='d-flex justify-content-end mt-4 border-top pt-4'>
            <Button
              className='btn btn-blue ml-2 px-4 fz-12 font-weight-bold'
              type='submit'
              disabled={fetch}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LicensesNew;
