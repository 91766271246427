import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import CourseViewHeader from './components/CourseViewHeader';
import Accordion from 'app/Main/components/Accordion';
import CourseLessonHeader from './components/CourseLessonHeader';
import CourseLessonBody from './components/CourseLessonBody';
import AuthorsList from './components/AuthorsList';
import PublishPreview from './components/PublishPreview';
import { Link } from 'react-router-dom';
import { IconKey } from 'shared/components/Icons';

const subsTypes = {
  1: 'Opened',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required{' '}
    </>
  ),
};

const Courses = ({ match }) => {
  const [data, setData] = useState();
  const isPreview = match.url.split('/').pop() === 'preview';

  const getCourseData = async id => {
    const { data } = await Api.get(`/courses/getcourse/${id}`);
    data.data.courseLessons = data.data.courseLessons.map(item => item.lesson);
    data.data.duration = data.data.courseLessons.reduce((a, b) => ({
      duration: a.duration + b.duration,
    })).duration;
    setData(data.data);
  };

  useEffect(() => {
    getCourseData(match.params.id);
  }, [match.params.id]);

  if (!data) return <Loading />;

  return (
    <div>
      <CourseViewHeader title={data.title} isPreview={isPreview} />
      <div className='py-3 d-flex has-header'>
        <div className='col-9'>
          <div className='px-3'>
            <h5 className='py-3 color-blue'>{data.title}</h5>
            {data.info && (
              <p className='text-muted'>
                <span className='d-block mb-2 text-lg'>About course</span>
                <span className='d-block white-space-pre'>{data.info}</span>
              </p>
            )}
            {data.courseAuthors && data.courseAuthors.length > 0 && (
              <AuthorsList data={data.courseAuthors} />
            )}
            {data.subscriptionPlans && (
              <div className='my-4'>
                <p className='text-secondary '>Subscription plans</p>
                <div>
                  <table className='table table-custom'>
                    <thead>
                      <tr>
                        <th scope='col'>Subscriptions plans title</th>
                        <th scope='col'>Type</th>
                        <th scope='col'>Content</th>
                        <th scope='col'>Duration</th>
                        <th scope='col'>Validity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.subscriptionPlans &&
                        data.subscriptionPlans.map(item => {
                          return (
                            <tr key={item.subscriptionId}>
                              <td>{item.title}</td>
                              <td className='text-nowrap'>{subsTypes[item.subscriptionType]}</td>
                              <td className='text-nowrap'>{item.courses} Courses</td>
                              <td className='text-nowrap'>{item.courseDuration} Hours</td>
                              <td className='text-nowrap'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div>{item.planDuration} Days</div>
                                  <Link
                                    to={`/subscription/${item.subscriptionId}/view`}
                                    className='bg-white btn btn-sm ml-3 weight-600'
                                  >
                                    More
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {data.courseLessons && data.courseLessons.length > 0 && (
              <Accordion
                data={data.courseLessons}
                HeaderComponent={CourseLessonHeader}
                BodyComponent={CourseLessonBody}
                childrenKey='episodes'
                className='parts-accordion mt-5'
                childrenClassName='mb-2'
              />
            )}
          </div>
        </div>
        <div className='col-3 py-3'>
          <PublishPreview
            title={data.title}
            imageUrl={data.imageUrl}
            duration={data.duration}
            courseLessons={`${data.courseLessons.length} parts`}
            registeredUsers={data.registeredUsers}
            courseAuthors={`${data.courseAuthors.length}`}
            publishable={data.courseStatus === 2}
            courseId={match.params.id}
          />
        </div>
      </div>
    </div>
  );
};

export default Courses;
