import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const filters = [
  { name: 'All Admins', path: '/admins/all' },
  { name: 'Active', path: '/admins/1' },
  { name: 'Inactive', path: '/admins/2' },
  { name: 'Invited', path: '/admins/4' },
];

const UsersHeader = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('admin_create') && (
          <Link className='btn btn-invite text-nowrap' to='/admins/new'>
            + Invite New Admin
          </Link>
        )}
        <div className='align-items-center d-flex mx-2 filters'>
          {filters.map(item => (
            <NavLink
              key={item.path}
              className='mx-3 text-nowrap'
              to={item.path}
              activeClassName='active'
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default UsersHeader;
