import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import Header from './components/Header';
import Lists from './components/Lists';
import ReactPaginate from 'react-paginate';

const CourseViewUsers = ({ match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [course, setCourse] = useState();
  const [users, setUsers] = useState([]);

  const [order, setOrder] = useState({ order: true, orderKey: 'registeredAt' });
  const [fetch, setFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [reActivateFetch, setReActivateFetch] = useState(false);

  const reActivateSubs = async (userId, subId) => {
    try {
      setReActivateFetch(true);
      const { data } = await Api.post(`subscription/extendusersubscription/${userId}/${subId}`);
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setReActivateFetch(false);
    }
  };

  const getUsersList = useCallback(async () => {
    try {
      setFetch(true);
      const params = { ...order, page: page + 1, limit: 8 };
      const { data } = await Api.get(`/courses/engaged-users/${id}`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setUsers(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [enqueueSnackbar, order, page, id]);

  const getCourseData = async () => {
    const { data } = await Api.get(`/courses/getcourse/${id}`);
    setCourse(data.data);
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    getCourseData();
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getUsersList();
    //eslint-disable-next-line
  }, [getUsersList, page, order]);

  if (!course) return <Loading className='mt-5' />;

  return (
    <div>
      <Header course={course} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists
            users={users}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            reActivateFetch={reActivateFetch}
            reActivateSubs={reActivateSubs}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default CourseViewUsers;
