import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { bindDuration, getError } from 'utils/appHelpers';
import moment from 'moment';
import BarChart from 'shared/components/BarChart';
import Loading from 'shared/components/Loading';
import {
  SIconResolution,
  SIconLatency,
  SIconBandwidth,
} from 'shared/components/StatisticTestIcons';
import { Api } from 'utils/connectors';

const beautifyValue = num => Number(Number(num).toFixed(1));

const StatisticRow = ({ label, value, isLast }) => {
  return (
    <li className={`d-flex align-items-center mb-2 pb-2 ${!isLast ? 'border-bottom' : ''}`}>
      <div className='flex-fill text-truncate'>{label}</div> <div className='col-6'>{value}</div>
    </li>
  );
};

const UserStatistics = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [latencyStats, setLatencyStats] = useState(null);
  const [statistics, setStatistics] = useState(null);

  const getStats = async () => {
    try {
      const { data } = await Api.get(`/statistics/user/${user.id}`);
      if (data && data.data.ldor && typeof data.data.ldor === 'string') {
        data.data.ldor = JSON.parse(data.data.ldor);
      }
      setStatistics(data.data);
      manageStats(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const manageStats = data => {
    const result = [
      {
        name: 'Lessons are passed',
        value: data.potlp,
        trueValue: `${beautifyValue(data.potlp)}%`,
      },
      {
        name: 'Courses are passed',
        value: data.cdcp,
        trueValue: `${beautifyValue(data.cdcp)}%`,
      },
      {
        name: 'Subscriptions are passed',
        value: data.posdcep,
        trueValue: `${beautifyValue(data.posdcep)}%`,
      },
    ];
    setLatencyStats(result);
  };

  useEffect(() => {
    getStats();
    //eslint-disable-next-line
  }, []);

  if (!statistics) return <Loading className='mt-5' />;

  const ldor = statistics.ldor;

  const total = user.userSubscriptions.filter(item =>
    [1, 3, 5].includes(item.userSubscriptionStatus),
  );

  const onProgress = [...total].filter(
    item => item.userSubscriptionStatus === 1 && item.progress < 100,
  ).length;

  const completed = [...total].filter(item => item.progress >= 100).length;

  return (
    <div className='mt-2'>
      <div>
        <div className='section-header d-flex align-items-center justify-content-between'>
          <h3>User Statistics</h3>
        </div>
        {latencyStats && <BarChart data={latencyStats} />}
      </div>
      {statistics && (
        <div className='col-12 p-0 mt-4'>
          <ul className='font-size-12 weight-400 p-0'>
            <StatisticRow label='Courses Registered:' value={total.length} />
            <StatisticRow label='Courses Progress:' value={onProgress} />
            <StatisticRow label='Courses Completed:' value={completed} />
            <StatisticRow
              label='Last session:'
              value={moment(statistics.llt).format('MM/DD/YYYY hh:mm')}
            />
            <StatisticRow
              label='Last session duration:'
              value={bindDuration(statistics.lsd || 0)}
            />
            <StatisticRow label='Last viewed lesson:' value={statistics.lwl.title || '-'} />
            <StatisticRow
              label='Last completed course:'
              value={statistics.lwl.lcc ? statistics.lwl.lcc.title : '-'}
            />
            {ldor && (
              <>
                <StatisticRow label='Screen resolution:' value={<SIconResolution data={ldor} />} />
                <StatisticRow label='Average latency:' value={<SIconLatency data={ldor} />} />
                <StatisticRow
                  label='Average bandwidth :'
                  value={<SIconBandwidth data={ldor} />}
                  isLast
                />
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserStatistics;
