import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import UploadProgress from 'shared/components/UploadProgress';
import { getError, imageUploadProgress } from 'utils/appHelpers';
import IconCases from 'assets/wizard/select_cases.svg';
import { Api } from 'utils/connectors';
import dicomParser from 'dicom-parser';
import { infoKeys, makeDeIdentifiedValue } from './parser';

const InfoBlock = ({ children, title }) => (
  <div className='card mb-3 dicom-info-block'>
    <div className='card-header'>{title}</div>
    <div className='card-body p-2 d-flex flex-wrap'>{children}</div>
  </div>
);

const InfoLine = ({ name, value }) => (
  <div className='col-6 px-1 border-right border-bottom d-flex justify-content-between'>
    <div className='col-4 p-0'>{name}:</div>
    <div className='col-4 p-0'>
      <span>{value.original}</span>
    </div>
    <div className='col-4 p-0 text-info'>
      <span>{value.decode}</span>
    </div>
  </div>
);

const CasesNew = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploadProgress, setUploadProgress] = useState();
  const [file, setFile] = useState();
  const [parsedData, setParsedData] = useState();
  const [dicomData, setDicomData] = useState();
  const [fetching, setFetching] = useState(false);

  const parseByteArray = byteArray => {
    try {
      const dataSet = dicomParser.parseDicom(byteArray);
      const result = {};
      Object.keys(infoKeys).forEach(key => {
        const original = dataSet.string(key);
        const decode = makeDeIdentifiedValue(original.length, infoKeys[key]);
        result[key] = { original, decode };
        const element = dataSet.elements[key];
        for (var i = 0; i < element.length; i++) {
          const text = decode || original;
          const char = text.length > i ? text.charCodeAt(i) : 32;
          dataSet.byteArray[element.dataOffset + i] = char;
        }
      });
      setParsedData(result);
      setDicomData(dataSet);
    } catch (err) {
      enqueueSnackbar('Not supported format of  DICOM file', { variant: 'error' });
      setFile();
    }
  };

  const loadFile = file => {
    const reader = new FileReader();
    reader.onload = function(file) {
      const arrayBuffer = reader.result;
      const byteArray = new Uint8Array(arrayBuffer);
      parseByteArray(byteArray);
    };
    reader.readAsArrayBuffer(file);
  };

  const onFileSelect = async e => {
    try {
      const file = e.target.files[0];
      loadFile(file);
      setFile(file);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const startUploadDicom = async e => {
    const config = {
      onUploadProgress: imageUploadProgress.bind(null, setUploadProgress),
    };
    try {
      setFetching(true);
      const blob = new Blob([dicomData.byteArray], { type: 'application/dicom' });
      const newFile = new File([blob], file.name, { type: 'application/dicom' });
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('isdraft', 'false');
      await Api.post('/upload', formData, config);
      enqueueSnackbar('Successfully uploaded', { variant: 'success' });
      history.push('/cases/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
      setUploadProgress();
    }
  };

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <span>Upload New DICOM</span>
        </div>
      </div>
      <div className='has-header'>
        <div className='d-flex'>
          <div className='px-2 col-7'>
            <label className='col-12 upload-wizard-block pointer' htmlFor='file'>
              <input
                id='file'
                name='file'
                type='file'
                accept='.dcm'
                className='flex-fill hide'
                onChange={onFileSelect}
              />
              <div className='d-flex flex-column align-items-center'>
                <img src={IconCases} alt='icon' />
                {uploadProgress && <UploadProgress progress={uploadProgress} />}
                {!file && 'Select File'}
                {file && <span>{file.name}</span>}
              </div>
            </label>
          </div>
          {dicomData && (
            <div className='col-5'>
              <p className='text-lg font-weight-normal'>
                Dicom data headers anonymization successfully completed. Please upload a new
                anonymized Dicom.
              </p>
              <button className='btn btn-success' onClick={startUploadDicom} disabled={fetching}>
                Upload Anonymized Dicom
              </button>
            </div>
          )}
        </div>
        {parsedData && (
          <div className='px-2'>
            <InfoBlock title='Patient Information'>
              <InfoLine name='Patient Name' value={parsedData['x00100010']} />
              <InfoLine name='Patient ID' value={parsedData['x00100020']} />
              <InfoLine name='Patient Birth Date' value={parsedData['x00100030']} />
              <InfoLine name='Patient Sex' value={parsedData['x00100040']} />
            </InfoBlock>
            <InfoBlock title='Study Information'>
              <InfoLine name='Study Description' value={parsedData['x00081030']} />
              <InfoLine name='Protocol Name' value={parsedData['x00181030']} />
              <InfoLine name='Accession #' value={parsedData['x00080050']} />
              <InfoLine name='Study Id' value={parsedData['x00200010']} />
              <InfoLine name='Study Date' value={parsedData['x00080020']} />
              <InfoLine name='Study Time' value={parsedData['x00080030']} />
            </InfoBlock>
            <InfoBlock title='Series Information'>
              <InfoLine name='Series Description' value={parsedData['x0008103e']} />
              <InfoLine name='Series #' value={parsedData['x00200011']} />
              <InfoLine name='Modality' value={parsedData['x00080060']} />
              <InfoLine name='Body Part' value={parsedData['x00180015']} />
              <InfoLine name='Series Date' value={parsedData['x00080021']} />
              <InfoLine name='Series Time' value={parsedData['x00080031']} />
            </InfoBlock>
          </div>
        )}
      </div>
    </div>
  );
};

export default CasesNew;
