// Load vendors
import axios from 'axios';

// Load configs
import { HOST } from 'configs/host';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: {
    common: {
      platform: 'admin',
    },
  },
});

export const setAuthToken = (authToken, api) => {
  api.defaults.headers.common[HOST.API.AUTH_HEADER] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};
