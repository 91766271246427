import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import LessonGeneralStep from './components/LessonGeneralStep';
import LessonContentFileStep from './components/LessonContentFileStep';
import LessonCompleteStep from './components/LessonCompleteStep';
import LessonVideoEpisodes from './components/LessonVideoEpisodes';
import { getError, getEpisodeStartTime } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import LessonCasesStep from './components/LessonCasesStep';
import LessonCasesQuestions from './components/LessonCasesQuestions';
import LessonCasesAnswers from './components/LessonCasesAnswers';
import Loading from 'shared/components/Loading';
import LessonCasesHeatmaps from './components/LessonCasesHeatmaps';
import { checkAnswersCaseCompleted, checkQuestionaryCaseCompleted } from './utils';

const LessonsNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [isInUse, setIsInUse] = useState(false);
  const [lesson, setLesson] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    caseHeatmaps: {},
    activeCases: [],
    hasFinding: {},
    tags: [],
  });
  const [form, setForm] = useState({
    name: '',
    nickName: '',
    description: '',
    uploadImageId: 0,
    uploadContentId: 0,
    contentType: 1,
  });

  const typeData = {
    1: {
      type: 'video',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonContentFileStep },
        { component: LessonVideoEpisodes },
        { component: LessonCompleteStep },
      ],
    },
    2: {
      type: 'pdf',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonContentFileStep },
        { component: LessonCompleteStep },
      ],
    },
    3: {
      type: 'case',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonCasesStep },
        { component: LessonCasesQuestions },
        { component: LessonCasesAnswers },
        { component: LessonCasesHeatmaps },
        { component: LessonCompleteStep },
      ],
    },
    4: {
      type: 'image',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonContentFileStep },
        { component: LessonCompleteStep },
      ],
    },
  };

  const constructCaseFormData = (form, data) => {
    const result = { ...form, cases: [] };
    const { activeCases, caseAnswers, caseQuestions, caseHeatmaps, hasFinding } = data;

    const constructQuestions = id => {
      const hasQuestions = hasFinding && Number(hasFinding[id] || 0) === 2;
      if (!caseQuestions || !caseQuestions[id]) return undefined;
      return hasQuestions ? caseQuestions[id] : [];
    };

    const constructAnswers = id => {
      if (!caseAnswers || !caseAnswers[id]) return undefined;
      if (caseAnswers[id].sections) {
        caseAnswers[id].sections.forEach((item, key) => {
          caseAnswers[id].sections[key].descriptions = item.descriptions.split(/(?:\r\n|\r|\n)/g);
        });
      }
      return caseAnswers[id];
    };

    const constructHeatmaps = id => {
      if (!caseHeatmaps || !caseHeatmaps[id]) return undefined;
      const data = caseHeatmaps[id];
      if (!data.hasHeatmap) return undefined;
      return [{ dicomId: data.dicomId, imageId: data.imageId }];
    };

    activeCases.forEach(item => {
      result.cases.push({
        caseId: item.caseId,
        caseUniqueId: item.caseUniqueId,
        questions: constructQuestions(item.caseId),
        answers: constructAnswers(item.caseId),
        heatmaps: constructHeatmaps(item.caseId),
      });
    });
    return result;
  };

  const constructFormData = form => {
    form.contentType = parseInt(form.contentType, 10);
    form.tags = data.tags && data.tags.map(i => i.value);
    if (form.contentType === 3) return constructCaseFormData(form, data);
    if (form.episodes) {
      form.episodes = form.episodes.map((item, i) => ({
        ...item,
        canSkipAfter: parseInt(item.canSkipAfter, 10),
        duration: parseInt(item.duration, 10),
        startTime: getEpisodeStartTime(form.episodes, i, true),
      }));
    }
    return form;
  };

  const onLessonSave = async () => {
    try {
      setFetching(true);
      const body = constructFormData(form);
      const endpoint = id ? '/wizard/lesson/update' : '/wizard/lesson/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar('Lesson successfully created', { variant: 'success' });
      history.push('/lessons/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setInitialEpsiodes = data => {
    if (!data) return null;
    return data.map(item => {
      item.name = item.title;
      return item;
    });
  };

  const setInitialQuestions = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      result[item.caseId] = item.questions;
    });
    return result;
  };

  const setInitialHasFindinsg = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      result[item.caseId] = item.questions && item.questions.length ? 2 : 1;
    });
    return result;
  };

  const setInitialCaseAnswers = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      if (item.answers && item.answers.sections) {
        item.answers.sections = item.answers.sections.map(item => {
          item.descriptions = item.descriptions.join('\n');
          return item;
        });
      }
      result[item.caseId] = item.answers || {};
    });
    return result;
  };

  const setInitialCaseHeatmaps = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      if (item.hasHeatmap) {
        result[item.caseId] = (item.heatmaps && item.heatmaps[0]) || {};
      } else {
        result[item.caseId] = { hasHeatmap: 0 };
      }
    });
    return result;
  };

  const setInitialTags = data => {
    if (!data) return [];
    return data.map(tag => ({ label: tag.name, value: tag.id }));
  };

  const setLessonInitialData = lesson => {
    setForm({
      name: lesson.title || '',
      nickName: lesson.nickName || '',
      description: lesson.info || '',
      contentType: lesson.contentType,
      episodes: setInitialEpsiodes(lesson.episodes),
    });
    setData({
      activeCases: lesson.cases,
      caseQuestions: setInitialQuestions(lesson.cases),
      hasFinding: setInitialHasFindinsg(lesson.cases),
      caseAnswers: setInitialCaseAnswers(lesson.cases),
      caseHeatmaps: setInitialCaseHeatmaps(lesson.cases),
      tags: setInitialTags(lesson.lessonTags),
      lessonImage: {
        url: lesson.imageUrl,
      },
      contentFile: {
        duration: lesson.duration,
        fileName: lesson.contentUrl,
        url: lesson.contentUrl,
      },
    });
  };

  const getLessonData = async id => {
    try {
      const { data } = await Api.get(`/wizard/lesson/${id}`);
      const { lesson, inUse } = data.data;
      setIsInUse(inUse);
      setLesson(lesson);
      setLessonInitialData(lesson);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/lessons/all');
    }
  };

  useEffect(() => {
    if (id) getLessonData(id);
    //eslint-disable-next-line
  }, [id]);

  const activeType = typeData[form.contentType];
  const typeSteps = activeType.steps;
  const isLastStep = activeStep === typeSteps.length - 1;
  const Step = activeType.steps[activeStep].component;

  const isDisableNext = form => {
    if (!!id && isInUse) return false;
    const isCases = Number(form.contentType) === 3;
    if (!isCases) return false;
    if (activeStep === 1) {
      return !data.activeCases || !data.activeCases.length;
    }
    if (activeStep === 2) {
      const items = data.activeCases.map(item => checkQuestionaryCaseCompleted(data, item.caseId));
      const failds = items.filter(item => item === false);
      return failds.length;
    }
    if (activeStep === 3) {
      const items = data.activeCases.map(item => checkAnswersCaseCompleted(data, item.caseId));
      const failds = items.filter(item => item === false);
      return failds.length;
    }
    if (activeStep === 4) {
      const items = data.activeCases.filter(item => {
        const caseI = data.caseHeatmaps && data.caseHeatmaps[item.caseId];
        return caseI && (caseI.imageId || !caseI.hasHeatmap);
      });
      return items.length !== data.activeCases.length;
    }
    return false;
  };

  if (id && !lesson) return <Loading className='mt-5' />;

  return (
    <div className='lesson-creation'>
      <Header steps={typeSteps} activeStep={activeStep} />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onLessonSave={onLessonSave}
            fetching={fetching}
            isInUse={!!isInUse}
            isEditing={!!id}
          />
        </div>
        <FooterButtons
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
          disableNext={isDisableNext(form)}
        />
      </form>
    </div>
  );
};

export default LessonsNew;
