import React, { useEffect } from 'react';
import { arrSum, getEpisodeStartTime } from 'utils/appHelpers';
import EditingInput from 'shared/components/editingForms/EditingInput';

const episodeItem = {
  name: '',
  startTime: '',
  duration: '',
  canSkipAfter: '',
};

const LessonVideoEpisodes = ({ form, setForm, data, isInUse }) => {
  const initEpisoded = () => {
    setForm({ ...form, episodes: [{ ...episodeItem }] });
  };

  const addEpisode = () => {
    setForm({ ...form, episodes: [...form.episodes, { ...episodeItem }] });
  };

  const removeEpisode = index => {
    const tempEpisodes = form.episodes;
    tempEpisodes.splice(index, 1);
    setForm({ ...form, episodes: tempEpisodes });
  };

  const handleChange = (index, { target }) => {
    const { name, value } = target;
    const tempEpisodes = form.episodes;
    tempEpisodes[index][name] = value;
    setForm({ ...form, episodes: tempEpisodes });
  };

  useEffect(() => {
    if (!form.episodes) initEpisoded();
    //eslint-disable-next-line
  }, []);

  const allDurSum = form.episodes ? arrSum(form.episodes, 'duration') : 0;
  const isInvalid = allDurSum > (data.contentFile && data.contentFile.duration);

  return (
    <div className='px-2 d-flex'>
      <div className='col-7 p-0'>
        <h6 className='color-blue mb-3'>Video Episodes Settings</h6>
        {form.episodes &&
          form.episodes.map((item, index) => {
            const isFirst = index === 0;
            const isLast = index + 1 === form.episodes.length;
            const episodeStartTime = getEpisodeStartTime(form.episodes, index);
            return (
              <div key={index} className='mb-4 episode-block'>
                <div className='d-flex justify-content-between align-items-center'>
                  <p>Episode {index + 1}</p>
                  {!isFirst && (
                    <button
                      type='button'
                      className='btn btn-red px-3'
                      disabled={isInUse}
                      onClick={removeEpisode.bind(null, index)}
                    >
                      Remove Step
                    </button>
                  )}
                </div>
                <div className='user-creation-form episode-form'>
                  <ul className='p-0 mb-0'>
                    <li className='d-flex align-items-center'>
                      <div className='col-4 pl-0'>
                        <label className='m-0'>Epsiode Name*:</label>
                      </div>
                      <EditingInput
                        type='text'
                        name='name'
                        value={item.name}
                        onChange={handleChange.bind(null, index)}
                        className='p-0'
                        placeholder='Epsiode Name'
                        required
                        editing={!isInUse}
                      />
                    </li>
                    <li className='d-flex align-items-center'>
                      <div className='col-4 pl-0'>
                        <label className='m-0'>Episode Start Time:</label>
                      </div>
                      <b>{episodeStartTime}</b>
                    </li>
                    <li className='d-flex align-items-center'>
                      <div className='col-4 pl-0'>
                        <label className='m-0'>Episode Duration (sec)*:</label>
                      </div>
                      <EditingInput
                        type='number'
                        name='duration'
                        value={item.duration}
                        onChange={handleChange.bind(null, index)}
                        className='p-0'
                        placeholder='Ex. 40 Seconds'
                        hasError={isInvalid}
                        required
                        editing={!isInUse}
                      />
                    </li>
                    <li className='d-flex align-items-center'>
                      <div className='col-4 pl-0'>
                        <label className='m-0'>Can Skip After (sec):</label>
                      </div>
                      <EditingInput
                        type='number'
                        name='canSkipAfter'
                        value={item.canSkipAfter}
                        onChange={handleChange.bind(null, index)}
                        className='p-0'
                        placeholder='Ex. 30 Seconds'
                        editing={!isInUse}
                      />
                    </li>
                  </ul>
                </div>
                {isLast && (
                  <div className='text-right'>
                    <button
                      type='button'
                      disabled={isInUse}
                      className='btn btn-blue'
                      onClick={addEpisode}
                    >
                      Add Episode +
                    </button>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className='col-5 pl-4'>
        {data.contentFile && (
          <video width='100%' className='mb-2' controls>
            <source src={data.contentFile.url} type='video/mp4' />
          </video>
        )}
      </div>
    </div>
  );
};

export default LessonVideoEpisodes;
