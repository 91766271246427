import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import LessonHeader from './components/LessonHeader';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import LessonsLists from './components/LessonsLists';
import useDebounce from 'shared/hooks/useDebounce';
import { confirmAlert } from 'react-confirm-alert';

const Lessons = () => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [lessons, setLessons] = useState([]);

  const [order, setOrder] = useState({ order: false, orderKey: 'id' });
  const [typeFilter, setTypeFilter] = useState('');
  const [usageFilter, setUsageFilter] = useState('');
  const [fetch, setFetch] = useState(0);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getLessons = useCallback(async () => {
    try {
      setFetch(true);
      const params = {
        ...order,
        page: page + 1,
        limit: 8,
        type: typeFilter,
        patientId: debouncedSearch,
        used: usageFilter,
      };
      const { data } = await Api.get(`/courses/lessons`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setLessons(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
    //eslint-disable-next-line
  }, [enqueueSnackbar, order, page, typeFilter, debouncedSearch, usageFilter]);

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/courses/lesson/${item.id}`);
      await getLessons();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.used > 0) {
      confirmAlert({
        title: 'Can not be deleted',
        message: 'This Lesson is in use and can not be deleted now.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Lesson?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  useEffect(() => {
    getLessons();
    //eslint-disable-next-line
  }, [getLessons, page, order, typeFilter, debouncedSearch, usageFilter]);

  return (
    <div>
      <LessonHeader
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        usageFilter={usageFilter}
        setUsageFilter={setUsageFilter}
        onSearch={text => setSearch(text)}
      />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <LessonsLists
            list={lessons}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Lessons;
