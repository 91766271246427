import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import ExportList from 'shared/components/ExportList';
import { hasAccess } from 'utils/permissionHelper';
import { useSelector } from 'react-redux';

const filters = [
  { name: 'All Users', path: '/users/all' },
  { name: 'Active', path: '/users/1' },
  { name: 'Pending', path: '/users/3' },
  { name: 'Invited', path: '/users/4' },
  { name: 'Archive', path: '/users/2' },
];

const exportData = [
  { name: 'Completed Learners (PDF)', id: 2 },
  { name: 'All Learners (PDF)', id: 1 },
  { name: 'All Learners (CSV)', id: 3 },
];

const UsersHeader = ({ onSearch }) => {
  const account = useSelector(state => state.account);
  const entityId = account.entity ? account.entity.id : '';
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('users_create') && (
          <Link className='btn btn-invite text-nowrap' to='/users/new'>
            + Invite New User
          </Link>
        )}
        {hasAccess('entity_manager') && (
          <Link className='btn btn-invite' to={`/entity/${entityId}/invite`}>
            + Invite New Member
          </Link>
        )}
        <div className='align-items-center d-flex mx-2 filters'>
          {filters.map(item => (
            <NavLink
              key={item.path}
              className='mx-3 text-nowrap'
              to={item.path}
              activeClassName='active'
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className='d-flex'>
        <ExportList data={exportData} type='users' />
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default UsersHeader;
