import React from 'react';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import FilterDropDown from 'shared/components/FilterDropDown';
import { IconSearch } from 'shared/components/Icons';
import { lessonTypes } from 'app/Main/configs';

const filterUsage = [{ name: 'Used', id: 1 }, { name: 'Unused', id: 2 }];

const LessonHeader = ({ typeFilter, setTypeFilter, usageFilter, setUsageFilter, onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('lessons_create') && (
          <Link className='btn btn-invite' to='/lessons/new'>
            + Add New Lesson
          </Link>
        )}
        <FilterDropDown
          className='ml-4 filter-drop-down'
          title='Type'
          data={lessonTypes}
          activeItemId={typeFilter}
          onItemClick={id => setTypeFilter(id)}
        />
        <FilterDropDown
          className='ml-4 filter-drop-down'
          title='Usage'
          data={filterUsage}
          activeItemId={usageFilter}
          onItemClick={id => setUsageFilter(id)}
        />
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder='Case ID / Name'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default LessonHeader;
