import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const filters = [{ name: 'All Entities', path: '/entities' }];

const Header = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('entity_create') && (
          <Link className='btn btn-invite' to='/entities/new'>
            + Add New Entity
          </Link>
        )}
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink key={item.path} className='mx-3' to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            className='form-control'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
