import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import FilterDropDown from 'shared/components/FilterDropDown';
import { IconSearch } from 'shared/components/Icons';
import BackButton from 'shared/components/BackButton';

const filters = [
  { name: 'All Keys', path: '/licenses/all' },
  { name: 'Assigned', path: '/licenses/1' },
  { name: 'Unused', path: '/licenses/2' },
];

const LicensesHeader = ({
  entityFilter,
  setEntityFilter,
  onSearch,
  entities,
  entityId,
  history,
}) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {entityId && (
          <BackButton className='pt-2' history={history} route={`/entity/${entityId}`} />
        )}
        {hasAccess('license_create') && (
          <Link className='btn btn-invite' to={`/licenses/new/${entityId || ''}`}>
            + Add New License
          </Link>
        )}
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink key={item.path} exact className='mx-3' to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          ))}
        </div>
        {hasAccess('entity_list_view') && (
          <FilterDropDown
            className='filter-drop-down'
            title='Entities'
            data={entities}
            activeItemId={entityFilter}
            onItemClick={id => setEntityFilter(id)}
          />
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder=''
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default LicensesHeader;
