import {
  IconUsers,
  IconHome,
  IconCourses,
  IconLessons,
  IconAdmins,
  IconAuthorsNew2,
  IconStatistics,
  IconKey,
  IconSubscription,
  IconContacts,
  IconReports,
  IconWarehouse,
  IconEntity,
  IconCertificates,
  IconCog,
} from 'shared/components/Icons';
import { reportTypes } from 'configs';

const reportNav = reportTypes.map(item => {
  return {
    name: item.name,
    path: `/reports/${item.value}`,
    permission: 'reports_import',
  };
});

export const navigation = [
  {
    name: 'Home',
    path: '/home',
    permission: 'home_view',
    icon: IconHome,
  },
  {
    name: 'Entity',
    path: '/entity',
    permission: 'entity_manager',
    icon: IconEntity,
  },
  {
    name: 'Subscriptions',
    path: '/subscriptions',
    permission: 'subscriptions_view',
    icon: IconSubscription,
    menu: [
      {
        name: 'Add New plan',
        path: '/subscriptions/new',
        permission: 'subscription_create',
      },
      {
        name: 'Requests',
        path: '/subscription/requests/all',
        permission: 'subscriptions_join_request_view',
      },
      {
        name: 'All plans',
        path: '/subscriptions/all',
        permission: 'subscriptions_list_view',
      },
    ],
  },
  {
    name: 'Courses',
    path: '/courses',
    permission: 'courses_list_view',
    icon: IconCourses,
    menu: [
      {
        name: 'Add New Course',
        path: '/courses/new',
        permission: 'courses_create',
      },
      {
        name: 'All Courses',
        path: '/courses/all',
        permission: 'courses_list_view',
      },
      // {
      //   name: 'Active courses',
      //   path: '/courses/1',
      //   permission: 'courses_list_view',
      // },
      // {
      //   name: 'Draft courses',
      //   path: '/courses/2',
      //   permission: 'courses_list_view',
      // },
      {
        name: 'Courses Statistics',
        path: '/courses/statistics',
        permission: 'courses_statistics_view',
      },
    ],
  },
  {
    name: 'Lessons',
    path: '/lessons/all',
    permission: 'lessons_list_view',
    icon: IconLessons,
    menu: [
      {
        name: 'Add New Lesson',
        path: '/lessons/new',
        permission: 'lessons_create',
      },
      {
        name: 'All Lessons',
        path: '/lessons/all',
        permission: 'lessons_list_view',
      },
    ],
  },
  {
    name: 'Certificates',
    path: '/certificates',
    permission: 'certificates_list_view',
    icon: IconCertificates,
    menu: [
      {
        name: 'Add New Certificates',
        path: '/certificate/new',
        permission: 'certificates_create',
      },
      {
        name: 'All Certificates',
        path: '/certificates',
        permission: 'certificates_list_view',
      },
    ],
  },

  {
    name: 'Media Warehouse',
    path: '/cases/all',
    permission: 'cases_list_view',
    icon: IconWarehouse,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'Upload New DICOM',
        path: '/cases/new',
        permission: 'cases_create',
      },
      {
        name: 'All Cases',
        path: '/cases/all',
        permission: 'cases_list_view',
      },
    ],
  },
  {
    name: 'Users',
    path: '/users',
    permission: 'users_list_view',
    icon: IconUsers,
    menu: [
      {
        name: 'Invite New User',
        path: '/users/new',
        permission: 'users_create',
      },
      {
        name: 'All Users',
        path: '/users/all',
        permission: 'users_list_view',
      },
      {
        name: 'Active Users',
        path: '/users/1',
        permission: 'users_list_view',
      },
      {
        name: 'Users Pending',
        path: '/users/3',
        permission: 'users_list_view',
      },
      {
        name: 'Invited Users',
        path: '/users/4',
        permission: 'users_list_view',
      },
      {
        name: 'Users Archive',
        path: '/users/2',
        permission: 'users_list_view',
      },
    ],
  },
  {
    name: 'Entities',
    path: '/entities',
    permission: 'entity_list_view',
    icon: IconEntity,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'Add New Entity',
        path: '/entities/new',
        permission: 'entity_create',
      },
      {
        name: 'All Entities',
        path: '/entities',
        permission: 'entity_list_view',
      },
    ],
  },
  {
    name: 'Licenses',
    path: '/licenses/all',
    permission: 'license_list_view',
    icon: IconKey,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'Add New License',
        path: '/licenses/new',
        permission: 'license_create',
      },
      {
        name: 'All License Keys',
        path: '/licenses/all',
        permission: 'license_list_view',
      },
      {
        name: 'Assigned Keys',
        path: '/licenses/1',
        permission: 'license_list_view',
      },
      {
        name: 'Unassigned Keys',
        path: '/licenses/2',
        permission: 'license_list_view',
      },
    ],
  },
  {
    name: 'Faculty',
    path: '/authors',
    permission: 'authors_list_view',
    icon: IconAuthorsNew2,
    menu: [
      {
        name: 'Create New Faculty',
        path: '/authors/new',
        permission: 'author_create',
      },
      {
        name: 'All Faculty',
        path: '/authors/all',
        permission: 'authors_list_view',
      },
    ],
  },
  {
    name: 'Statistics',
    path: '/statistics',
    permission: 'statistics_view',
    icon: IconStatistics,
  },
  {
    name: 'Admins',
    path: '/admins',
    permission: 'admins_list_view',
    icon: IconAdmins,
    menu: [
      {
        name: 'Invite New Admin',
        path: '/admins/new',
        permission: 'admin_create',
      },
      {
        name: 'All Admins',
        path: '/admins/all',
        permission: 'admins_list_view',
      },
      {
        name: 'Active Admins',
        path: '/admins/1',
        permission: 'admins_list_view',
      },
      {
        name: 'Inactive Admins',
        path: '/admins/2',
        permission: 'admins_list_view',
      },
      {
        name: 'Invited Admins',
        path: '/admins/4',
        permission: 'admins_list_view',
      },
    ],
  },
  {
    name: 'Support',
    path: '/support/tickets',
    permission: 'support_tickets_view',
    icon: IconContacts,
    menu: [
      {
        name: 'All Requests',
        path: '/support/tickets',
        permission: 'support_tickets_view',
      },
      {
        name: 'System Emails',
        path: '/support/emails',
        permission: 'support_emails_view',
      },
    ],
  },
  {
    name: 'Reports',
    path: reportNav[0].path,
    permission: 'reports_import',
    icon: IconReports,
    menu: [...reportNav],
  },
  {
    name: 'System',
    path: '/system/logs',
    permission: 'system_logs_view',
    icon: IconCog,
    menu: [
      {
        name: 'Logs',
        path: '/system/logs',
        permission: 'system_logs_view',
      },
    ],
  },
];
