import React from 'react';

const EditingTextArea = ({
  type,
  name,
  value,
  placeholder,
  required,
  onChange,
  className,
  editing,
  maxLength,
}) =>
  editing ? (
    <textarea
      type={type}
      name={name}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      value={value}
      required={required}
      maxLength={maxLength}
    />
  ) : (
    <span className='result'>{value}</span>
  );

export default EditingTextArea;
