import React, { useEffect, useState } from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { checkAnswersCaseCompleted, findDicom, getDicomsSelects } from '../utils';
import removeIcon from 'assets/bin.svg';
import LessonCasesBlock from './LessonCasesBlock';
import { getBase64, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';

const answerTypes = [
  { name: 'DICOM Case', value: 'case' },
  { name: 'Static Image', value: 'image' },
];

const getEmptyFrame = () => ({
  dicomId: null,
  imageId: null,
});

const getEmptyStepObj = () => ({
  image: '',
  stepType: 'case',
  filename: '',
  title: '',
  leftFrame: '',
  rightFrame: '',
  caseLeftFrame: { ...getEmptyFrame() },
  caseRightFrame: { ...getEmptyFrame() },
});

const getEmptyDescObj = () => ({
  title: '',
  descriptions: '',
});

const getEmptyAnswerObj = () => ({
  sections: [{ ...getEmptyDescObj() }],
  steps: [{ ...getEmptyStepObj() }, { ...getEmptyStepObj() }, { ...getEmptyStepObj() }],
});

const getInitialConfigs = (caseId, dicoms, initData) => {
  const confs = initData[caseId];
  if (!confs) return null;

  confs.steps.forEach(step => {
    if (step.caseLeftFrame && step.caseLeftFrame.dicomId) {
      const dicom = dicoms.find(i => i.id === step.caseLeftFrame.dicomId);
      if (!dicom) return;
      let sliceIndex;
      if (dicom.dicomImages) {
        sliceIndex = dicom.dicomImages.findIndex(i => i.id === step.caseLeftFrame.imageId);
      }
      step.caseLeftFrame = {
        ...step.caseLeftFrame,
        imageType: dicom.imageType,
        imageLaterality: dicom.imageLaterality,
        viewPosition: dicom.viewPosition,
        slice: sliceIndex + 1,
      };
    }
    if (step.caseRightFrame && step.caseRightFrame.dicomId) {
      const dicom = dicoms.find(i => i.id === step.caseRightFrame.dicomId);
      if (!dicom) return;
      let sliceIndex;
      if (dicom.dicomImages) {
        sliceIndex = dicom.dicomImages.findIndex(i => i.id === step.caseRightFrame.imageId);
      }
      step.caseRightFrame = {
        ...step.caseRightFrame,
        imageType: dicom.imageType,
        imageLaterality: dicom.imageLaterality,
        viewPosition: dicom.viewPosition,
        slice: sliceIndex + 1,
      };
    }
  });
  return confs;
};

const setDicomIds = (dicoms, answers) => {
  if (!dicoms) return {};
  Object.keys(answers).forEach(caseId => {
    const data = dicoms[caseId];
    const form = answers[caseId];
    form.steps.forEach(step => {
      const leftForm = step.caseLeftFrame;
      const rightForm = step.caseRightFrame;
      if (leftForm.imageType && leftForm.imageLaterality && leftForm.viewPosition) {
        const dicom = findDicom(data, leftForm);
        if (dicom) {
          leftForm.dicomId = dicom.id;
          if (leftForm.slice) {
            const image = dicom.dicomImages[leftForm.slice - 1];
            if (image) leftForm.imageId = image.id;
          }
        }
      }
      if (rightForm.imageType && rightForm.imageLaterality && rightForm.viewPosition) {
        const dicom = findDicom(data, rightForm);
        if (dicom) {
          rightForm.dicomId = dicom.id;
          if (rightForm.slice) {
            const image = dicom.dicomImages[rightForm.slice - 1];
            if (image) rightForm.imageId = image.id;
          }
        }
      }
    });
  });
  return answers;
};

const LessonCasesAnswers = ({ data, setData, isInUse, isEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentCase, setCurrentCase] = useState(data.activeCases[0] && data.activeCases[0].caseId);
  const [caseAnswers, setCaseAnswers] = useState(data.caseAnswers || {});
  const [dicoms, setDicoms] = useState({});

  const getCaseDicoms = async id => {
    try {
      const patientId = data.activeCases.find(i => i.caseId === id).patientId;
      const res = await Api.get(`/cases/case/${patientId}?images=true`);
      setDicoms({ ...dicoms, [id]: res.data.data.dicoms });
      if (isEditing) {
        const initConfs = getInitialConfigs(id, res.data.data.dicoms, data.caseAnswers);
        if (initConfs) setCaseAnswers({ ...caseAnswers, [id]: initConfs });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const fillAnswers = () => {
    const active = caseAnswers[currentCase];

    if (active && active.sections && !active.sections.length) {
      setCaseAnswers({
        ...caseAnswers,
        [currentCase]: { ...active, sections: [{ ...getEmptyDescObj() }] },
      });
    }

    if (active && active.steps && active.steps.length) {
      if (active.steps.length === 1) {
        setCaseAnswers({
          ...caseAnswers,
          [currentCase]: {
            ...active,
            steps: [...active.steps, { ...getEmptyStepObj() }, { ...getEmptyStepObj() }],
          },
        });
      }
      return;
    }

    if (active && (!active.steps || !active.steps.length)) {
      setCaseAnswers({
        ...caseAnswers,
        [currentCase]: { ...active, steps: [...getEmptyAnswerObj().steps] },
      });
      return;
    }

    setCaseAnswers({
      ...caseAnswers,
      [currentCase]: { ...getEmptyAnswerObj() },
    });
  };

  const onAnswerChange = (index, { target }) => {
    const tempData = { ...caseAnswers };
    tempData[currentCase].sections[index][target.name] = target.value;
    setCaseAnswers(tempData);
  };

  const onStepCommentChange = (index, { target }) => {
    const tempData = { ...caseAnswers };
    tempData[currentCase].steps[index][target.name] = target.value;
    setCaseAnswers(tempData);
  };

  const onAddAnswer = () => {
    const tempData = { ...caseAnswers };
    tempData[currentCase].sections.push({ ...getEmptyDescObj() });
    setCaseAnswers(tempData);
  };

  const onRemoveAnswer = index => {
    const tempData = { ...caseAnswers };
    tempData[currentCase].sections.splice(index, 1);
    setCaseAnswers(tempData);
  };

  const handleImageSelect = (index, file) => {
    getBase64(file, result => {
      const tempData = { ...caseAnswers };
      tempData[currentCase].steps[index].image = result;
      tempData[currentCase].steps[index].filename = file.name;
      setCaseAnswers(tempData);
    });
  };

  const handleFrameChange = (index, type, e) => {
    const tempData = { ...caseAnswers };
    const step = tempData[currentCase].steps[index];
    let { name, value } = e.target;
    step[type] = { ...step[type], [name]: value };
    setCaseAnswers(tempData);
  };

  useEffect(() => {
    if (currentCase && !dicoms[currentCase]) getCaseDicoms(currentCase);
    //eslint-disable-next-line
  }, [currentCase]);

  useEffect(() => {
    fillAnswers();
    //eslint-disable-next-line
  }, [currentCase]);

  useEffect(() => {
    const caseAnswersWithids = setDicomIds(dicoms, caseAnswers);
    setData({ ...data, caseAnswers: { ...data.caseAnswers, ...caseAnswersWithids } });
    //eslint-disable-next-line
  }, [caseAnswers]);

  const activeItem = caseAnswers[currentCase];

  const checkCaseCompleted = ({ caseId }) => {
    return checkAnswersCaseCompleted(data, caseId);
  };

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Imagery Answers Settings</h6>
      <LessonCasesBlock
        cases={data.activeCases}
        activeCase={currentCase}
        onClickCase={setCurrentCase}
        isCompletedCase={checkCaseCompleted}
      />
      <div className='col-9 p-0 user-creation-form'>
        {activeItem &&
          activeItem.sections.map((item, index) => {
            const disableremove = index === 0 && activeItem.sections.length < 2;
            const isLast = index + 1 === activeItem.sections.length;
            return (
              <ul className='p-0 mb-0' key={index}>
                <div className='d-flex drag-item mb-2'>
                  <li className='d-flex align-items-center w-100'>
                    <div className='col-3 pl-0 mr-3'>
                      <label className='m-0'>Answer Title {index + 1}*:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='title'
                      value={item.title}
                      onChange={onAnswerChange.bind(null, index)}
                      className='p-0'
                      placeholder='Enter Name'
                      required
                      editing={!isInUse}
                      access={true}
                      maxLength='255'
                    />
                  </li>
                  <button
                    onClick={onRemoveAnswer.bind(null, index)}
                    disabled={disableremove || isInUse}
                    type='button'
                    className='btn btn-remove ml-2'
                  >
                    <img src={removeIcon} alt='icon' />
                  </button>
                </div>
                <li className='d-flex d-flex'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Answer Description {index + 1}*:</label>
                  </div>
                  <EditingTextArea
                    type='text'
                    name='descriptions'
                    value={item.descriptions || ''}
                    onChange={onAnswerChange.bind(null, index)}
                    className='p-0 is-large'
                    placeholder='Enter Description'
                    required
                    editing={!isInUse}
                    access={true}
                  />
                </li>
                <div className='d-flex justify-content-end add-new-items-btns'>
                  {isLast && (
                    <button
                      type='button'
                      disabled={isInUse}
                      className='btn btn-blue'
                      onClick={onAddAnswer}
                    >
                      Add Answer +
                    </button>
                  )}
                </div>
              </ul>
            );
          })}
        {activeItem &&
          activeItem.steps.map((item, index) => {
            const isCase = item.stepType === 'case';
            const leftData = getDicomsSelects(dicoms[currentCase], item.caseLeftFrame);
            const rightData = getDicomsSelects(dicoms[currentCase], item.caseRightFrame);
            return (
              <ul className='p-0 mt-4' key={`step + ${index + 1}`}>
                <h3 className='step-title'>Substep {index + 1}</h3>

                <li className='d-flex align-items-center col'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Answer Type</label>
                  </div>
                  <EditingSelect
                    name='stepType'
                    onChange={onStepCommentChange.bind(null, index)}
                    value={item.stepType}
                    items={answerTypes}
                    useProperty='value'
                    displayProperty='name'
                    editing={!isInUse}
                    hideDefault
                    required
                  />
                </li>

                {isCase && (
                  <div className='d-flex'>
                    <div className='col p-0'>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Frame 1*:</label>
                        </div>
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Type*:</label>
                        </div>
                        <EditingSelect
                          name='imageType'
                          onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                          value={item.caseLeftFrame.imageType || ''}
                          items={leftData.types || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Side*:</label>
                        </div>
                        <EditingSelect
                          name='imageLaterality'
                          onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                          value={item.caseLeftFrame.imageLaterality || ''}
                          items={leftData.sides || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Angle*:</label>
                        </div>
                        <EditingSelect
                          name='viewPosition'
                          onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                          value={item.caseLeftFrame.viewPosition || ''}
                          items={leftData.angles || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Slice*:</label>
                        </div>
                        <EditingSelect
                          name='slice'
                          onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                          value={item.caseLeftFrame.slice || ''}
                          items={leftData.slices || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                    </div>
                    <div className='col p-0 ml-2'>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Frame 2*:</label>
                        </div>
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Type*:</label>
                        </div>
                        <EditingSelect
                          name='imageType'
                          onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                          value={item.caseRightFrame.imageType || ''}
                          items={rightData.types || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Side*:</label>
                        </div>
                        <EditingSelect
                          name='imageLaterality'
                          onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                          value={item.caseRightFrame.imageLaterality || ''}
                          items={rightData.sides || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Angle*:</label>
                        </div>
                        <EditingSelect
                          name='viewPosition'
                          onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                          value={item.caseRightFrame.viewPosition || ''}
                          items={rightData.angles || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                      <li className='d-flex align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Slice*:</label>
                        </div>
                        <EditingSelect
                          name='slice'
                          onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                          value={item.caseRightFrame.slice || ''}
                          items={rightData.slices || []}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={!isInUse}
                        />
                      </li>
                    </div>
                  </div>
                )}

                {!isCase && (
                  <li className='d-flex align-items-center'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Image:</label>
                    </div>
                    <div className='flex-fill d-flex align-items-center justify-content-between'>
                      <span className='text-secondary font-weight-light pl-2'>
                        {item.image || item.imageUrl ? (
                          <img
                            height='40'
                            className='mr-2'
                            src={item.image || item.imageUrl}
                            alt='img'
                          />
                        ) : (
                          'Not Selected'
                        )}
                      </span>
                      <input
                        id={`file${index}`}
                        name={`file${index}`}
                        type='file'
                        className='flex-fill hide'
                        onChange={e => handleImageSelect(index, e.target.files[0])}
                      />
                      <label
                        htmlFor={`file${index}`}
                        className='btn-sm px-4 btn btn-white m-0 pointer'
                      >
                        Choose Image
                      </label>
                    </div>
                  </li>
                )}

                <li className='d-flex'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Step Comments:</label>
                  </div>
                  <EditingTextArea
                    type='text'
                    name='title'
                    value={item.title || ''}
                    onChange={onStepCommentChange.bind(null, index)}
                    className='p-0 is-small'
                    placeholder='Enter Description'
                    editing={!isInUse}
                    access={true}
                  />
                </li>
              </ul>
            );
          })}
      </div>
    </div>
  );
};

export default LessonCasesAnswers;
