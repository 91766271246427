import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import ButtonLine from 'shared/components/ButtonLine';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import AuthBlock from '../components/AuthBlock';
import { IconMail } from 'shared/components/Icons';
import SuccessInfo from '../components/SuccessInfo';

const Forgot = () => {
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const authOptions = {
    title: 'Password reset',
    subTitle: 'Please enter your working email.',
    links: { signin: true },
  };

  const successInfo = {
    title: 'Success!',
    subTitle: 'Please check your email and follow the link to reset your password',
    className: 'mt-5 pt-5',
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      await Api.post(`/admin/forgot`, { email });
      setSuccess(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  return (
    <AuthBlock {...authOptions} successBlock={success ? <SuccessInfo {...successInfo} /> : null}>
      <form onSubmit={handleSubmit} className='mt-5'>
        <InputGroup
          type='text'
          name='email'
          onChange={e => setEmail(e.target.value)}
          className='mb-4'
          placeholder='Email address'
          value={email}
          icon={<IconMail />}
          required
        />
        {error && <div className='error-text'>{error}</div>}
        <ButtonLine type='submit' disabled={fetch}>
          Next
        </ButtonLine>
      </form>
    </AuthBlock>
  );
};

export default Forgot;
