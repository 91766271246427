/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';
import {
  getImageUrl,
  getEndOfSubs,
  getError,
  courseSubsStatuses,
  checkSubsCourseOpened,
} from 'utils/appHelpers';
import ClickArea from 'shared/components/ClickArea';
import ProgressBar from '../../UserProgress/components/ProgressBar';
import { userStatuses } from 'app/Main/configs';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';

const headers = [
  {
    name: 'First Name',
    className: 'col-2',
    key: 'firstName',
  },
  {
    name: 'Surname',
    className: 'col-1',
    key: 'lastName',
  },
  {
    name: 'Institution',
    className: 'col-1',
    key: 'institution',
  },
  {
    name: 'Status',
    className: 'col-1',
    key: 'userStatus',
  },
  {
    name: 'Subscription',
    className: 'col-2',
    key: false,
  },
  {
    name: 'Progress',
    className: 'col-2',
    key: false,
  },
  {
    name: 'End of subscription',
    className: 'col border-right-0',
    key: '',
  },
  {
    name: '',
    className: '',
    key: '',
  },
];

const inviteHeaders = [
  {
    name: 'First Name',
    className: 'col-2',
    key: 'firstName',
  },
  {
    name: 'Surname',
    className: 'col-2',
    key: 'lastName',
  },
  {
    name: 'Email',
    className: 'col-3',
    key: 'email',
  },
  {
    name: 'Status',
    className: 'col border-right-0',
    key: 'userStatus',
  },
  {
    name: '',
    className: '',
    key: '',
  },
];

const UsersLists = ({ users, order, changeOrder, status, fetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userInviteFetch, setUserInviteFetch] = useState(false);

  if (fetch) return <Loading className='mt-5' />;

  if (!users || !users.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder(items => ({
      ...items,
      orderKey: item.key,
      order: !order.order,
    }));
  };

  const userResetInviteEmail = async id => {
    try {
      setUserInviteFetch(true);
      await Api.post(`/admin/resendinvitation/${id}`, {});
      enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserInviteFetch(false);
    }
  };

  const showSubscription = ({ userSubscriptionStatus: status }) => {
    return courseSubsStatuses(status, 'pending') || courseSubsStatuses(status, 'active');
  };

  const invitedStatus = Number(status) === 4;
  const rowHeaders = invitedStatus ? inviteHeaders : headers;

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {rowHeaders.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map(item => {
            const subs = item.userSubscriptions ? item.userSubscriptions : [];
            const profile = item.learnerProfile || {};
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='col-2 d-flex align-items-center'>
                  <img
                    src={getImageUrl(item.imageUrl)}
                    alt={item.firstname}
                    className='user-image mr-2'
                  />
                  <div className='text-elips'>{item.firstName}</div>
                </div>
                <div className={`align-items-center col-1 d-flex`}>
                  <span className='text-elips'>{item.lastName}</span>
                </div>
                {invitedStatus && (
                  <div className={`${invitedStatus ? 'col-3' : 'col-2'} d-flex align-items-center`}>
                    <span className='text-elips'>{item.email}</span>
                  </div>
                )}
                {!invitedStatus && (
                  <div className={`align-items-center col-1 d-flex`}>
                    <span className='text-elips'>{profile.institution}</span>
                  </div>
                )}
                <div className={`${invitedStatus ? 'col' : 'col-1'} d-flex align-items-center`}>
                  {invitedStatus && 'Invitation Sent'}
                  {!invitedStatus && (userStatuses[item.userStatus] || '-')}
                </div>
                {!invitedStatus && (
                  <>
                    <div className='col-2 d-flex flex-column justify-content-center'>
                      {subs.map(item => {
                        if (!showSubscription(item)) return '';
                        return <div key={item.id}>{item.subscription.title}</div>;
                      })}
                      {!subs.length && '-'}
                    </div>
                    <div className='col-2 d-flex flex-column justify-content-center'>
                      {subs.map(item => {
                        if (!showSubscription(item)) return '';
                        const isCourseOpened = checkSubsCourseOpened(item);
                        if (isCourseOpened) return <div key={item.id}>No Progress</div>;
                        return (
                          <div key={item.id} className='align-items-center d-flex'>
                            <b className='col-2 mr-2 pl-0'>{item.progress}%</b>
                            <ProgressBar progress={item.progress} />
                          </div>
                        );
                      })}
                      {!subs.length && '-'}
                    </div>
                    <div className='col d-flex flex-column justify-content-center'>
                      {subs.map(item => {
                        if (!showSubscription(item)) return '';
                        const expirable = item.subscription && item.subscription.expirable;
                        return (
                          <div key={item.id}>{getEndOfSubs(item.endDate, !expirable) || '-'}</div>
                        );
                      })}
                      {!subs.length && '-'}
                    </div>
                  </>
                )}
                <div className={`p-2 d-flex justify-content-end`}>
                  {invitedStatus && (
                    <button
                      className='more-btn small mr-2'
                      disabled={userInviteFetch}
                      onClick={() => userResetInviteEmail(profile.userId || item.id)}
                    >
                      Resend
                    </button>
                  )}
                  <Link
                    className='more-btn small'
                    to={`/user/${profile.userId || item.id}/${
                      invitedStatus || item.userStatus == 4 ? 'invited' : ''
                    }`}
                  >
                    More <IconRight />
                  </Link>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default UsersLists;
