import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import ProgressHeader from './components/ProgressHeader';
import ProgressList from './components/ProgressList';
import Loading from 'shared/components/Loading';
import moment from 'moment';

const generateCourses = data => {
  const result = [];
  data.forEach(course => {
    result.push({
      id: course.course.id,
      name: course.course.title,
      progress: course.progress,
      start_date: moment(course.startDate).format('MM/DD/YYYY'),
      end_date: moment(course.endDate).format('MM/DD/YYYY'),
    });
  });
  return result;
};

const generatePlansData = data => {
  const result = [];

  data.forEach(plan => {
    const sub = plan.userSubscription || {};
    result.push({
      id: plan.id,
      name: plan.title,
      progress: sub.progress,
      start_date: moment(sub.startDate).format('MM/DD/YYYY'),
      end_date: moment(sub.endDate).format('MM/DD/YYYY'),
      courses: generateCourses(plan.userCourses),
    });
  });
  return result;
};

const UserProgress = ({ match }) => {
  const [data, setData] = useState();
  const [user, setUser] = useState();

  const getUserData = async id => {
    const res = await Api.get(`/auth/getuserbyid/${id}`);
    setUser(res.data.data);
  };

  const getUserSubs = async id => {
    const res = await Api.get(`/subscription/getusersubscriptionsbyuserid/${id}`);
    setData(generatePlansData(res.data.data));
  };

  useEffect(() => {
    getUserData(match.params.id);
    getUserSubs(match.params.id);
  }, [match.params.id]);

  if (!user || !data) return <Loading className='mt-5' />;

  return (
    <div>
      <ProgressHeader user={user} />
      <div className='py-3 has-header'>
        <div className='col-9'>
          <ProgressList data={data} />
        </div>
      </div>
    </div>
  );
};

export default UserProgress;
