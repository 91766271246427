import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import attachIcon from 'assets/attach.svg';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Select from 'react-select';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import TagsSelect from 'shared/components/TagsSelect';

const CourseGeneralStep = ({ form, setForm, data, setData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [franch, setFranch] = useState();

  const getFranchises = async () => {
    try {
      const { data } = await Api.get('/wizard/franchises');
      setFranch(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const newForm = { ...form };

    if (name === 'isCertificateAvailable' && checked && form.isOpened) {
      newForm['isOpened'] = false;
    }

    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    newForm[name] = val;
    setForm(newForm);
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setData({ ...data, lessonImage: res.data.data });
      setForm({ ...form, uploadImageId: res.data.data.id });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonImg = data.lessonImage && data.lessonImage.url;

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 300,
      color: '#dddddd',
    }),
  };

  useEffect(() => {
    getFranchises();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Course General Settings</h6>
      <div className='col-9 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Name*:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Image:</label>
            </div>
            <div className='upload-file-form w-100 overflow-hidden'>
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill hide'
                onChange={onImageUpload}
              />
              <label
                htmlFor='file'
                className={`d-flex justify-content-between align-items-center text-nowrap ${
                  lessonImg ? 'bg-transparent pl-0' : ''
                }`}
              >
                {lessonImg && <img height='40' className='mr-2' src={lessonImg} alt='img' />}
                {!lessonImg && 'Upload Image'}
                <div>
                  {lessonImg && 'Change Image'}
                  <img src={attachIcon} className='ml-2' alt='icon' />
                </div>
              </label>
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Duration*:</label>
            </div>
            <EditingInput
              type='number'
              name='duration'
              value={form.duration}
              onChange={handleChange}
              className='p-0'
              placeholder='Duration in hours'
              required
              editing={true}
              access={true}
            />
          </li>
          <li className='d-flex'>
            <div className='col-4 pl-0 pt-1'>
              <label className='m-0'>Short Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Description'
              required
              editing={true}
              access={true}
              maxLength='1024'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Nickname:</label>
            </div>
            <EditingInput
              type='text'
              name='nickName'
              value={form.nickName}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Nickname'
              editing={true}
              access={true}
              maxLength='255'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Evaluation Available:</label>
            </div>
            <EditingCheckBox
              name='isEvaluationAvailable'
              value={form.isEvaluationAvailable}
              onChange={handleChange}
              className='p-0'
              checked={form.isEvaluationAvailable}
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Certificate Provisioning:</label>
            </div>
            <EditingCheckBox
              name='isCertificateAvailable'
              value={form.isCertificateAvailable}
              onChange={handleChange}
              className='p-0'
              checked={form.isCertificateAvailable}
              editing={true}
            />
          </li>
          <li className='list-default'>
            <div className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Unordered Course:</label>
              </div>
              <EditingCheckBox
                name='isOpened'
                value={form.isOpened}
                onChange={handleChange}
                className='p-0'
                checked={form.isOpened}
                editing={true}
                disabled={form.isCertificateAvailable}
              />
            </div>
            <p className='small m-0 text-secondary mt-2'>
              The learner is not required to view all lessons in any particular order. This type of
              course has no certificate.
            </p>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Category:</label>
            </div>
            <Select
              styles={customStyles}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => o.name}
              getOptionValue={o => o.id}
              value={data.franchises}
              onChange={franchises => setData({ ...data, franchises })}
              isMulti
              options={franch}
            />
          </li>
          {/* <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Tags:</label>
            </div>
            <TagsSelect value={data.tags} onChange={tags => setData({ ...data, tags })} />
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default CourseGeneralStep;
