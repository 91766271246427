import React, { useState } from 'react';
import { IconEdit, IconCheck } from './Icons';
import Select from './Select';

const EditSelect = ({
  label,
  showValue,
  value,
  name,
  className,
  onSubmit,
  useProperty,
  displayProperty,
  items,
  access,
}) => {
  const [field, setField] = useState(value || '');
  const [editMode, setEditMode] = useState(false);

  const editOrSubmitHandle = () => {
    if (editMode && value !== field) onSubmit({ value: field, name });
    setEditMode(val => !val);
  };

  return (
    <div
      className={`blue-box d-flex justify-content-between align-items-center ${className || ''}`}
    >
      <div className='d-flex flex-fill align-items-center col-11 p-0'>
        {label && (
          <div className='col-3 pl-0'>
            <b>{label}</b>
          </div>
        )}
        {editMode ? (
          <Select
            name={name}
            onChange={e => setField(e.target.value)}
            value={field}
            items={items}
            useProperty={useProperty}
            displayProperty={displayProperty}
            parentClassName='col-5'
          />
        ) : (
          <span>{showValue || value || '-'}</span>
        )}
      </div>
      {access && (
        <div className='pointer' tabIndex='-1' role='button' onClick={editOrSubmitHandle}>
          {editMode ? <IconCheck /> : <IconEdit />}
        </div>
      )}
    </div>
  );
};

export default EditSelect;
