import React from 'react';
import { IconTick } from 'shared/components/Icons';

const Header = ({ steps, activeStep }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex pl-3'>
        <ul className='align-items-center d-flex mx-3 filters steps'>
          {steps.map((item, index) => {
            const isActive = activeStep === index;
            const isCompleted = activeStep > index;
            return (
              <li
                key={index}
                className={`d-flex align-items-center ${isActive ? 'active' : ''} ${
                  isCompleted ? 'completed' : ''
                }`}
              >
                Step {index + 1} {isCompleted && <IconTick className='ml-1' />}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Header;
