import React, { useState, useEffect } from 'react';
import EditBox from 'shared/components/EditBox';
import { IconRight } from 'shared/components/Icons';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError, getImageUrl } from 'utils/appHelpers';
import AvatarImage from 'shared/components/AvatarImage';
import { userStatuses } from 'app/Main/configs';
import { roles, rolesObj } from '../../AdminNew/configs';
import EditSelect from 'shared/components/EditSelect';
import { hasAccess } from 'utils/permissionHelper';

const AdminInfo = props => {
  const { enqueueSnackbar } = useSnackbar();
  const [adminStatusFetch, setAdminStatusFetch] = useState(false);
  const [userInviteFetch, setUserInviteFetch] = useState(false);
  const [emailSendFetch, setEmailSendFetch] = useState(false);
  const [data, setData] = useState(props.user);
  const { isInvited } = props;
  const user = data;

  const updateInfo = async body => {
    try {
      await Api.post('/admin/edituser', body);
      // setData(user =>
      //   body.learnerProfile
      //     ? { ...user, learnerProfile: { ...user.learnerProfile, ...body.learnerProfile } }
      //     : { ...user, ...body },
      // );
      props.getUserData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFieldChange = data => {
    const body = { [data.name]: data.value, userId: user.id };
    updateInfo(body);
  };

  const onUserEmailReset = async () => {
    try {
      setEmailSendFetch(true);
      await Api.post('/auth/forgot', { email: user.email });
      enqueueSnackbar('Email successfully sent', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setEmailSendFetch(false);
    }
  };

  const userResetInviteEmail = async () => {
    try {
      setUserInviteFetch(true);
      const { data } = await Api.post(`/admin/resendinvitation/${user.id}`, {});
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserInviteFetch(false);
    }
  };

  const adminToggleStatus = async isActive => {
    try {
      setAdminStatusFetch(true);
      const path = isActive ? 'deactivateuser' : 'activateuser';
      const { data } = await Api.post(`/admin/${path}/${user.id}`);
      enqueueSnackbar(data.message, { variant: 'success' });
      props.getUserData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setAdminStatusFetch(false);
    }
  };

  useEffect(() => {
    setData(props.user);
  }, [props.user]);

  const access = hasAccess('admin_create') && !isInvited;
  const status = isInvited ? 'Invited' : userStatuses[user.userStatus];

  return (
    <div className='user-info'>
      <div className='d-flex mb-3'>
        <div className='col-3 d-flex justify-content-center'>
          <AvatarImage
            url={getImageUrl(user.imageUrl)}
            userId={user.id}
            getUserData={props.getUserData}
            access={access}
          />
        </div>
        <div className='flex-fill'>
          <EditBox
            className='mb-2 pl-3'
            name='firstName'
            onSubmit={onFieldChange}
            value={user.firstName}
            access={access}
          />
          <EditBox
            className='mb-2 pl-3'
            name='lastName'
            onSubmit={onFieldChange}
            value={user.lastName}
            access={access}
          />
        </div>
      </div>
      <div className='mb-3'>
        <EditBox
          className='mb-2 pl-3'
          onSubmit={onFieldChange}
          name='email'
          label='Email:'
          value={user.email}
          access={false}
        />
      </div>
      <div className='mb-3'>
        <EditSelect
          name='userRole'
          label='Role:'
          onSubmit={onFieldChange}
          showValue={rolesObj[user.userRole]}
          value={user.userRole}
          items={roles}
          useProperty='code'
          displayProperty='name'
          access={access}
        />
      </div>
      <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex flex-fill'>
          <div className='col-3 mr-2 p-0'>
            <b>Status:</b>
          </div>
          <span>{isInvited ? 'Invitation Sent' : status}</span>
        </div>
        {status !== 'Invited' && hasAccess('admin_create') && (
          <button
            className='more-btn link-color d-flex align-items-center justify-content-center w-100p weight-600'
            onClick={adminToggleStatus.bind(null, user.userStatus === 1)}
            disabled={adminStatusFetch}
          >
            <span className='mr-1'>{user.userStatus === 1 ? 'Deactivate' : 'Activate'}</span>
            <IconRight />
          </button>
        )}
        {status === 'Invited' && (
          <button
            className='more-btn link-color d-flex align-items-center justify-content-center w-100p weight-600'
            onClick={userResetInviteEmail}
            disabled={userInviteFetch}
          >
            <span className='mr-1'>Resend Invite</span>
            <IconRight />
          </button>
        )}
      </div>
      {status === 'Active' && hasAccess('admin_create') && !isInvited && (
        <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex flex-fill col-11 p-0'>
            <div className='col-3 pl-0'>
              <b>User Password Reset:</b>
            </div>
            <span>Send request to {user.email}</span>
          </div>
          <div>
            <button
              className='more-btn link-color'
              disabled={emailSendFetch}
              onClick={onUserEmailReset}
            >
              Send <IconRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminInfo;
