import React, { useState, useEffect } from 'react';
import EditBox from 'shared/components/EditBox';
import ReactModal from 'react-modal';
import { IconRight, IconCheck } from 'shared/components/Icons';
import { useSnackbar } from 'notistack';
import LinkIcon from 'shared/components/LinkIcon';
import { Api } from 'utils/connectors';
import UserDeactivateModal from './UserDeactivateModal';
import {
  getError,
  getImageUrl,
  parseJson,
  getEndOfSubs,
  courseSubsStatuses,
} from 'utils/appHelpers';
import ProgressBar from '../../UserProgress/components/ProgressBar';
import AvatarImage from 'shared/components/AvatarImage';
import { userStatuses } from 'app/Main/configs';
import EditSelect from 'shared/components/EditSelect';
import { countries, specialty, degrees } from 'configs';
import StarRating from 'shared/components/StarRating';
import {
  SIconResolution,
  SIconLatency,
  SIconBandwidth,
} from 'shared/components/StatisticTestIcons';
import { hasAccess } from 'utils/permissionHelper';
import UserSubscriptionModal from './UserSubscriptionModal';
import EntityInfoBlock from '../../Profile/components/EntityInfoBlock';

const UserInfo = props => {
  const { enqueueSnackbar } = useSnackbar();
  const [userStatusFetch, setUserStatusFetch] = useState(false);
  const [userInviteFetch, setUserInviteFetch] = useState(false);
  const [emailSendFetch, setEmailSendFetch] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [data, setData] = useState(props.user);
  const user = data;
  const profile = data.learnerProfile || {};
  const { isInvited } = props;
  const isEventView = hasAccess('entity_manager');

  const updateInfo = async body => {
    try {
      await Api.post('/admin/edituser', body);
      props.getUserData();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const findFromList = (list, field, equality, returnField) => {
    const value = list.find(v => v[field] === equality);
    return value ? value[returnField] : '';
  };

  const onFieldChange = data => {
    const body = { [data.name]: data.value, userId: user.id };
    updateInfo(body);
  };

  const onProfileFieldChange = data => {
    const body = { learnerProfile: { [data.name]: data.value }, userId: user.id };
    updateInfo(body);
  };

  const onUserEmailReset = async () => {
    try {
      setEmailSendFetch(true);
      await Api.post('/auth/forgot', { email: user.email });
      enqueueSnackbar('Email successfully sent', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setEmailSendFetch(false);
    }
  };

  const userActivate = async () => {
    try {
      setUserStatusFetch(true);
      const { data } = await Api.post(`/admin/activateuser/${user.id}`);
      enqueueSnackbar(data.message, { variant: 'success' });
      props.getUserData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserStatusFetch(false);
    }
  };

  const userStatusToggleClick = async () => {
    if (user.userStatus === 1) {
      setDeactivateModal(true);
    } else {
      userActivate();
    }
  };

  const userResetInviteEmail = async () => {
    try {
      setUserInviteFetch(true);
      const { data } = await Api.post(`/admin/resendinvitation/${user.id}`, {});
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserInviteFetch(false);
    }
  };

  const handleModalClose = () => {
    setDeactivateModal(false);
    props.getUserData();
  };

  const handleSubModalClose = () => {
    setSubscriptionModal(false);
    props.getUserData();
  };

  useEffect(() => {
    setData(props.user);
  }, [props.user]);

  const { userSubscriptions, connectionTests } = data;

  const subs = userSubscriptions
    ? userSubscriptions.filter(
        i =>
          courseSubsStatuses(i.userSubscriptionStatus, 'active') ||
          courseSubsStatuses(i.userSubscriptionStatus, 'pending'),
      )
    : [];
  const testData =
    connectionTests && connectionTests.length ? connectionTests[connectionTests.length - 1] : false;
  const details = testData ? parseJson(testData.testData) : {};

  const status = isInvited ? 'Invited' : userStatuses[user.userStatus];
  const canEdit = !isInvited && hasAccess('users_edit') && !isEventView;

  return (
    <div className='user-info col-12'>
      <div className='d-flex mb-3'>
        <div className='d-flex justify-content-center'>
          <AvatarImage
            url={getImageUrl(user.imageUrl)}
            userId={user.id}
            getUserData={props.getUserData}
            access={canEdit}
          />
        </div>
        <div className='flex-fill'>
          <div className='d-flex justify-content-between'>
            <EditBox
              className='mt-2 pl-3 p-2 col'
              name='firstName'
              onSubmit={onFieldChange}
              value={user.firstName}
              access={canEdit}
              placeholder='Name'
            />
            <EditBox
              className='mt-2 ml-2 pl-3 p-2 col'
              name='lastName'
              onSubmit={onFieldChange}
              value={user.lastName}
              access={canEdit}
              placeholder='Surname'
            />
          </div>
          <EditSelect
            className='mt-2 pl-3 p-2'
            name='speciality'
            useProperty='value'
            displayProperty='name'
            onSubmit={onProfileFieldChange}
            value={profile.speciality}
            items={specialty}
            access={canEdit}
          />
        </div>
      </div>
      {user.entity && <EntityInfoBlock entity={user.entity} />}
      {!isInvited && (
        <div className='blue-box mb-3 d-flex justify-content-between'>
          <div className='px-2 pt-1'>
            <p className='mb-2'>Recent Connection and Device test:</p>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>Bandwidth:</span>
              </span>
              <SIconBandwidth className='mr-1' data={details} />
              {details.bandwidth
                ? `received ${details.bandwidth.received} MB in ${details.bandwidth.duration}s`
                : '-'}
            </div>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>Screen Resolution:</span>
              </span>
              <SIconResolution className='mr-1' data={details} />
              {details.resolution ? details.resolution.screen : '-'}
            </div>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>Window Resolution:</span>
              </span>
              <SIconResolution className='mr-1' data={details} />
              {details.resolution ? details.resolution.window : '-'}
            </div>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>Latency:</span>
              </span>
              <SIconLatency className='mr-1' data={details} />
              {details.latency
                ? `Average: ${details.latency.avg}, Min: ${details.latency.min}, Max: ${
                    details.latency.max
                  }`
                : '-'}
            </div>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>Browser:</span>
              </span>
              {details.browser ? details.browser : '-'}
            </div>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>OS System:</span>
              </span>
              {details.os ? details.os : '-'}
            </div>
            <div className='info d-block mb-1'>
              <span className='mr-2'>
                <IconCheck /> <span className='ml-1'>IP Address:</span>
              </span>
              {testData.hostAddress ? testData.hostAddress : '-'}
            </div>
          </div>
          <div className='align-items-end d-flex'>
            <LinkIcon className='link-color weight-600' to={`/user/${user.id}/tests`}>
              View History
            </LinkIcon>
          </div>
        </div>
      )}
      {!isInvited && (
        <div className='blue-box px-1 mb-3 d-flex flex-column justify-content-between'>
          <div className='d-flex'>
            <div className='col-4'>
              <p>Subscription Plan:</p>
            </div>
            <div className='col-4'>
              <p>Plan Expiration:</p>
            </div>
          </div>
          {subs && !!subs.length ? (
            subs.map(item => {
              const expirable = item.subscription && item.subscription.expirable;
              return (
                <div className='d-flex mb-2 align-items-center' key={item.id}>
                  <div className='col-4'>
                    <span className='info d-block'>{item.subscription.title}</span>
                  </div>
                  <div className='col-4'>
                    <span className='info d-block'>{getEndOfSubs(item.endDate, !expirable)}</span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='info d-block px-3'>No Active Subscriptions</div>
          )}
          {!isEventView && (
            <div className='px-3 text-right'>
              <LinkIcon
                to='#'
                className='link-color weight-600'
                onClick={e => {
                  e.preventDefault();
                  setSubscriptionModal(true);
                }}
              >
                Manage Subscription Plans
              </LinkIcon>
            </div>
          )}
        </div>
      )}
      {!!subs.length && (
        <div className='blue-box px-1 mb-3 d-flex flex-column justify-content-between'>
          <div className='d-flex'>
            <div className='col-4'>
              <p>Progress:</p>
            </div>
            <div className='col-4'>
              <p>Progress bar</p>
            </div>
          </div>
          {subs.map(item => {
            return (
              <div className='d-flex mb-3' key={item.id}>
                <div className='col-4'>
                  <span className='info d-block'>{item.subscription.title}</span>
                </div>
                <div className='col-4 d-flex align-items-center'>
                  <span className='mr-2 font-weight-bold'>{item.progress}%</span>
                  <ProgressBar progress={item.progress} />
                </div>
              </div>
            );
          })}
          <div className='px-3 text-right mt--20'>
            <LinkIcon className='link-color weight-600' to={`/user/${user.id}/progress`}>
              More Info
            </LinkIcon>
          </div>
        </div>
      )}
      {user.feedbacks && (
        <div className='blue-box px-1 mb-3 d-flex flex-column justify-content-between'>
          <div className='d-flex'>
            <div className='col-4'>
              <p>Evaluations:</p>
            </div>
          </div>
          {!!user.feedbacks.length && (
            <div className='mb-3 px-3'>
              {user.feedbacks.map(item => {
                return (
                  <div className='feedback-item border-top py-2' key={item.id}>
                    <p className='mb-1'>{item.title}</p>
                    <ul className='mb-0'>
                      {item.feedbacks.map(feed => {
                        return (
                          <li key={feed.id} className='d-flex'>
                            {feed.question}:{' '}
                            {feed.type === 'rating' ? (
                              <StarRating className='ml-2' isDisabled={true} rate={feed.value} />
                            ) : (
                              <span className='font-weight-bolder ml-2'>{feed.value}</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          )}
          {!user.feedbacks.length && (
            <span className='info d-block px-3'>
              Evaluations will be available after completion of the course.
            </span>
          )}
        </div>
      )}
      <div className='mb-3'>
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onFieldChange}
          name='email'
          label='Email:'
          value={user.email}
          access={false}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='phone'
          label='Phone:'
          value={profile.phone}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='institution'
          label='Institution:'
          value={profile.institution}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='address'
          label='Institution Address:'
          value={profile.address}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='city'
          label='Institution City:'
          value={profile.city}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='state'
          label='Institution State/Province:'
          value={profile.state}
          access={canEdit}
        />
        <EditSelect
          className='mb-2 pl-3 p-2'
          name='countryId'
          label='Institution Country'
          useProperty='id'
          displayProperty='name'
          onSubmit={onFieldChange}
          showValue={findFromList(countries, 'id', Number(user.countryId), 'name')}
          value={user.countryId}
          items={countries}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='zipCode'
          label='Institution ZIP Code:'
          value={profile.zip}
          access={canEdit}
        />
        <EditSelect
          className='mb-2 pl-3 p-2'
          name='degree'
          label='Degree'
          useProperty='value'
          displayProperty='name'
          onSubmit={onFieldChange}
          showValue={findFromList(degrees, 'value', profile.degree, 'name')}
          value={profile.degree}
          items={degrees}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='medicalLicense'
          label='Medical Licence:'
          value={profile.medicalLicense}
          access={canEdit}
        />
        <EditBox
          className='mb-2 pl-3 p-2'
          onSubmit={onProfileFieldChange}
          name='npi'
          label='NPI:'
          value={profile.npi}
          access={canEdit}
        />
      </div>
      <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex flex-fill'>
          <div className='col-3 mr-3 p-0'>
            <b>User Actions History</b>
          </div>
        </div>
        <button className='more-btn link-color d-flex align-items-center justify-content-center w-100p weight-600'>
          <LinkIcon
            className='link-color weight-600 text-decoration-none'
            to={`/user/${user.id}/actions`}
          >
            View
          </LinkIcon>
        </button>
      </div>
      {user.certificates && (
        <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex flex-fill'>
            <div className='col-3 mr-3 p-0'>
              <b>Certificates:</b>
            </div>
            <span>{user.certificates.length}</span>
          </div>
          <button className='more-btn link-color d-flex align-items-center justify-content-center w-100p weight-600'>
            <LinkIcon
              className='link-color weight-600 text-decoration-none'
              to={`/user/${user.id}/certificate`}
            >
              View
            </LinkIcon>
          </button>
        </div>
      )}
      <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex flex-fill'>
          <div className='col-3 mr-3 p-0'>
            <b>Status:</b>
          </div>
          <span>{isInvited ? 'Invitation Sent' : status}</span>
        </div>
        {status !== 'Invited' && !isEventView && (
          <button
            className='more-btn link-color d-flex align-items-center justify-content-center w-100p weight-600'
            onClick={userStatusToggleClick}
            disabled={userStatusFetch}
          >
            <span className='mr-1'>{user.userStatus === 1 ? 'Deactivate' : 'Activate'}</span>
            <IconRight />
          </button>
        )}
        {status === 'Invited' && (
          <button
            className='more-btn link-color d-flex align-items-center justify-content-center w-100p weight-600'
            onClick={userResetInviteEmail}
            disabled={userInviteFetch}
          >
            <span className='mr-1'>Resend Invite</span>
            <IconRight />
          </button>
        )}
      </div>
      {status === 'Active' && !isEventView && (
        <div className='blue-box d-flex justify-content-between align-items-center mb-3'>
          <div className='d-flex flex-fill'>
            <div className='col-3 mr-2 p-0'>
              <b>User Password Reset:</b>
            </div>
            <span>Send request to {user.email}</span>
          </div>
          <button
            className='more-btn link-color w-100p weight-600'
            disabled={emailSendFetch}
            onClick={onUserEmailReset}
          >
            Send <IconRight />
          </button>
        </div>
      )}
      <ReactModal isOpen={deactivateModal} className='custom-modal'>
        <UserDeactivateModal user={user} close={handleModalClose} />
      </ReactModal>
      <ReactModal isOpen={subscriptionModal} className='custom-modal'>
        <UserSubscriptionModal
          userSubs={userSubscriptions}
          user={user}
          close={handleSubModalClose}
          onSuccess={props.getUserData}
        />
      </ReactModal>
    </div>
  );
};

export default UserInfo;
