import React from 'react';
import PlanProgress from './PlanProgress';
import CourseProgress from './CourseProgress';
import Accordion from 'app/Main/components/Accordion';

const ProgressList = ({ data = [] }) => {
  return (
    <Accordion
      data={data}
      HeaderComponent={PlanProgress}
      BodyComponent={CourseProgress}
      childrenKey='courses'
      className='course-accordion'
    />
  );
};

export default ProgressList;
