import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import CourseGeneralStep from './components/CourseGeneralStep';
import CourseCompleteStep from './components/CourseCompleteStep';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import CourseAuthorsStep from './components/CourseAuthorsStep';
import CourseLessonsStep from './components/CourseLessonsStep';
import CourseFeedBackStep from './components/CourseFeedBackStep';
import CourseCertificates from './components/CourseCertificates';
import Loading from 'shared/components/Loading';

const CourseNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [isInUse, setIsInUse] = useState(false);
  const [course, setCourse] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    activeCases: [],
    franchises: [],
    tags: [],
  });
  const [form, setForm] = useState({
    certificateId: 0,
    name: '',
    nickName: '',
    description: '',
    uploadImageId: 0,
    uploadContentId: 0,
    duration: '',
    isOpened: false,
    isEvaluationAvailable: 1,
    isCertificateAvailable: 1,
  });

  const steps = [
    CourseGeneralStep,
    CourseAuthorsStep,
    CourseLessonsStep,
    CourseFeedBackStep,
    CourseCertificates,
    CourseCompleteStep,
  ];

  const constructFormData = form => {
    form.authors = data.activeAuthors && data.activeAuthors.map(i => i.authorId);
    form.lessons = data.activeLessons && data.activeLessons.map(i => i.lessonId);
    form.feedbacks = data.feedbacks;
    form.franchises = data.franchises && data.franchises.map(i => i.id);
    form.tags = data.tags && data.tags.map(i => i.value);
    return form;
  };

  const onCourseSave = async () => {
    try {
      setFetching(true);
      const body = constructFormData(form);
      const endpoint = id ? '/wizard/course/update' : '/wizard/course/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar('Course successfully created', { variant: 'success' });
      history.push('/courses/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setInitialAuthors = data => {
    if (!data) return [];
    return data.map(({ authorProfile: item }) => {
      item.firstName = item.name;
      item.lastName = item.surname;
      item.authorId = item.id;
      return item;
    });
  };

  const setInitialLessons = data => {
    if (!data) return [];
    return data.map(({ lesson: item }) => {
      item.lessonId = item.id;
      return item;
    });
  };

  const setInitialFeedbacks = data => {
    if (!data) return [];
    return data.map(({ feedback: item }) => {
      item.feedbackType = item.type;
      item.labels = item.feedbackItems ? item.feedbackItems.map(item => item.label) : [''];
      return item;
    });
  };

  const setInitialFranchises = data => {
    if (!data) return [];
    return data.map(({ franchise }) => franchise);
  };

  const setInitialTags = data => {
    if (!data) return [];
    return data.map(({ tag }) => ({ label: tag.name, value: tag.id }));
  };

  const setCourseInitialData = lesson => {
    setForm({
      name: lesson.title || '',
      nickName: lesson.nickName || '',
      description: lesson.info || '',
      duration: lesson.duration || '',
      isOpened: lesson.isOpened,
      isEvaluationAvailable: lesson.isEvaluationAvailable,
      isCertificateAvailable: lesson.isCertificateAvailable,
      certificateId: lesson.certificateId,
    });
    setData({
      activeAuthors: setInitialAuthors(lesson.courseAuthors),
      activeLessons: setInitialLessons(lesson.courseLessons),
      feedbacks: setInitialFeedbacks(lesson.courseFeedbacks),
      franchises: setInitialFranchises(lesson.courseFranchises),
      tags: setInitialTags(lesson.courseTags),
      lessonImage: {
        url: lesson.imageUrl,
      },
    });
  };

  const getCourseData = async id => {
    try {
      const { data } = await Api.get(`/wizard/course/${id}`);
      const { course, inUse } = data.data;
      setIsInUse(inUse);
      setCourse(course);
      setCourseInitialData(course);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/courses/all');
    }
  };

  useEffect(() => {
    if (id) getCourseData(id);
    //eslint-disable-next-line
    }, [id]);

  if (!form.isEvaluationAvailable) {
    steps.splice(steps.length - 3, 1);
  }

  if (!form.isCertificateAvailable) {
    steps.splice(steps.length - 2, 1);
  }

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && !course) return <Loading className='mt-5' />;

  return (
    <div className='lesson-creation'>
      <Header steps={steps} activeStep={activeStep} />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onCourseSave={onCourseSave}
            fetching={fetching}
            isInUse={!!isInUse}
          />
        </div>
        <FooterButtons
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
        />
      </form>
    </div>
  );
};

export default CourseNew;
