/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { formatDate } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import removeIcon from 'assets/bin.svg';

const headers = [
  {
    name: 'Course title',
    className: 'col-3',
    key: 'title',
  },
  {
    name: 'Faculty',
    className: 'col-1',
    key: 'faculty',
  },
  {
    name: 'Status',
    className: 'col-1',
    key: 'courseStatus',
  },
  {
    name: 'Registered',
    className: 'col-1',
    key: 'registeredUsers',
  },
  {
    name: 'Completed',
    className: 'col-2',
    key: 'completedUsers',
  },
  {
    name: 'Date Added',
    className: 'col-2',
    key: 'createdAt',
  },
  {
    name: '',
    className: 'col-2',
    key: '',
  },
];

const CoursesLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col-3 align-items-center'>{item.title}</div>
                <div className='col-1 align-items-center d-flex'>{item.faculty || '0'} faculty</div>
                <div className='col-1 align-items-center d-flex'>
                  {item.courseStatus === 1 ? 'Active' : 'Inactive'}
                </div>
                <div className='col-1 align-items-center d-flex'>
                  {item.registeredUsers || '0'} users
                </div>
                <div className='col-2 align-items-center d-flex'>
                  {item.completedUsers || '0'} users
                </div>
                <div className='col-2 align-items-center d-flex'>
                  {formatDate(item.createdAt) || '-'}
                </div>
                <div className='p-2 m-auto d-flex'>
                  <Link className='more-btn small mr-3' to={`/course/${item.id}/edit`}>
                    Edit <IconRight />
                  </Link>
                  <Link className='more-btn small mr-3' to={`/course/${item.id}`}>
                    More <IconRight />
                  </Link>
                  <button
                    onClick={onItemRemoveConfirm.bind(null, item)}
                    disabled={onItemRemoveDisabled}
                    type='button'
                    className='btn btn-sm btn-red list-remove-btn'
                  >
                    <img width='15' src={removeIcon} alt='icon' />
                  </button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CoursesLists;
