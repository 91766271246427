import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import Header from './components/Header';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';

const headers = [
  {
    name: 'Email Type',
    className: 'col-3',
  },
  {
    name: 'Subject',
    className: 'col-6',
  },
  {
    name: 'Target Audience',
    className: 'col-2',
  },
  {
    name: '',
    className: 'col-1',
  },
];

const SystemEmails = () => {
  const [emais, setEmails] = useState();

  const getSystemEmails = async id => {
    const { data } = await Api.get(`/common/email-template`);
    setEmails(data.data);
  };

  useEffect(() => {
    getSystemEmails();
  }, []);

  if (!emais) return <Loading className='mt-5' />;

  return (
    <div>
      <Header />
      <div className='py-3 d-flex has-header'>
        <div className='col-12'>
          <div className='users-lists'>
            <ul className='p-0'>
              <li className='d-flex list-header align-items-center'>
                {headers.map((item, index) => (
                  <div
                    key={item.name + index}
                    className={`d-flex text-body justify-content-between align-items-center ${
                      item.className
                    }`}
                  >
                    {item.name}
                  </div>
                ))}
              </li>
              {emais &&
                emais.map((item, i) => {
                  return (
                    <li key={i} className='d-flex align-items-center list-item'>
                      <div className='col-3 text-truncate'>{item.title}</div>
                      <div className='col-6 text-truncate'>{item.subject}</div>
                      <div className='col-2 text-capitalize'>{item.target}</div>
                      <div className='d-flex justify-content-end p-2 w-100'>
                        <Link
                          className='more-btn w-25 small'
                          to={`/support/emails/${item.emailType}`}
                        >
                          Edit <IconRight />
                        </Link>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemEmails;
