import React from 'react';
import { getImageUrl } from 'utils/appHelpers';

const CourseAuthorsRow = ({ item }) => {
  return (
    <div className='d-flex align-items-center course-authors-row'>
      <div className={`image-container`}>
        <img src={getImageUrl(item.imageUrl)} alt='img' />
      </div>
      <div className='author-name mb-1'>
        <h5>
          {item.firstName} {item.lastName} <span className='ml-2'>{item.profession}</span>
        </h5>
        <p>{item.info}</p>
      </div>
    </div>
  );
};

export default CourseAuthorsRow;
