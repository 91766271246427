import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import ButtonLine from 'shared/components/ButtonLine';
import Button from 'shared/components/Button';
import Loading from 'shared/components/Loading';

export const initRolesData = [{ name: 'Manager', value: 1 }, { name: 'Member', value: 2 }];

const EntityUserNew = ({ match, history }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [rolesData, setRolesData] = useState([...initRolesData]);
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    entityId: Number(id),
  });

  const getEntityData = async id => {
    if (!id) return;
    try {
      setFetch(true);
      const { data } = await Api.get(`/entity/getentity/${id}`);
      if (data.data.manager && data.data.manager.userStatus === 1) {
        const tempData = [...initRolesData];
        tempData.splice(0, 1);
        setRolesData(tempData);
      }
      setFetch(false);
    } catch (err) {
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onSubmitUser = async form => {
    try {
      setFetching(true);
      const endpoint = Number(form.role) === 1 ? '/entity/invitemanager' : '/entity/invitemember';
      await Api.post(endpoint, form);
      enqueueSnackbar('Successfully invited', { variant: 'success' });
      history.push(`/entity/${id}/users`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setEditing(false);
    if (!editing) {
      form.role = Number(form.role);
      onSubmitUser(form);
    }
  };

  useEffect(() => {
    getEntityData(id);
    //eslint-disable-next-line
  }, [id])

  if (fetch) return <Loading className='w-100 mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/entities'>All Entities</Link>
          <span className='mx-2'>/</span>
          <Link to={`/entity/${id}/users`}>Members</Link>
          <span className='mx-2'>/</span>
          <span>Invite New Member</span>
        </div>
      </div>
      <form className='py-3 has-header' onSubmit={handleSubmit}>
        <div className='col-7 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Name:</label>
              </div>
              <EditingInput
                type='text'
                name='firstname'
                value={form.firstname}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={editing}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Surname:</label>
              </div>
              <EditingInput
                type='text'
                name='lastname'
                value={form.lastname}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Surname'
                required
                editing={editing}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Email:</label>
              </div>
              <EditingInput
                type='email'
                name='email'
                value={form.email}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Email'
                required
                editing={editing}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Role:</label>
              </div>
              <EditingSelect
                name='role'
                onChange={handleChange}
                value={form.role}
                items={rolesData}
                useProperty='value'
                displayProperty='name'
                required={true}
                editing={editing}
              />
            </li>
          </ul>
          {!editing && (
            <p className='text-right invitation-dsc fz-12 color-blue mb-0'>
              The invitation will be sent to: {form.email}
            </p>
          )}
          <div className='d-flex justify-content-end mt-2'>
            {!editing && (
              <Button
                className='btn-blue mr-2 mt-1 px-4 fz-12 font-weight-bold'
                onClick={() => setEditing(!editing)}
              >
                Edit
              </Button>
            )}
            <ButtonLine
              className='btn btn-blue px-4 fz-12 font-weight-bold'
              type='submit'
              disabled={fetching}
            >
              {editing ? 'Next' : 'Invite'}
            </ButtonLine>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EntityUserNew;
