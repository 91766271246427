import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import LicensesHeader from './components/LicensesHeader';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import LicensesLists from './components/LicensesLists';
import useDebounce from 'shared/hooks/useDebounce';
import { confirmAlert } from 'react-confirm-alert';
import { hasAccess } from 'utils/permissionHelper';
import { useSelector } from 'react-redux';

const Licenses = ({ match, history }) => {
  const account = useSelector(state => state.account);
  const { filter, entityId } = match.params;
  const initialEntity = account.entity ? account.entity.id : entityId ? Number(entityId) : '';
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState({ order: true, orderKey: 'licenseKey' });
  const [entities, setEntities] = useState([]);
  const [entityFilter, setEntityFilter] = useState(initialEntity);
  const [fetch, setFetch] = useState(0);
  const [removeFetch, setRemoveFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getData = useCallback(async () => {
    try {
      setFetch(true);
      const status = filter === 'all' ? null : filter;
      const params = {
        ...order,
        page: page + 1,
        limit: 8,
        status,
        search: debouncedSearch,
        entity: entityFilter,
      };
      const { data } = await Api.get(`/entity/licenses`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
    //eslint-disable-next-line
  }, [enqueueSnackbar, order, page, entityFilter, debouncedSearch, filter]);

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/entity/entities`, { params });
      setEntities(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/entity/licensekey/${item.id}`);
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.used > 0) {
      confirmAlert({
        message: 'The scan is used in lesson(s) Detach it from lesson before removing.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Key Removal',
      message: 'Do you really want to remove the key from the System?',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [getData, page, order, entityFilter, debouncedSearch]);

  useEffect(() => {
    if (hasAccess('entity_list_view')) getEntities();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <LicensesHeader
        entities={entities}
        entityFilter={entityFilter}
        setEntityFilter={setEntityFilter}
        onSearch={text => setSearch(text)}
        entityId={entityId}
        history={history}
      />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <LicensesLists
            list={data}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Licenses;
