import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import Button from 'shared/components/Button';
import { validateEmail } from 'utils/validators';
import { degrees } from 'configs';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';

const UserCreationForm = ({ onSubmitUser, fetch }) => {
  const [editing, setEditing] = useState(true);
  const [errors, setError] = useState({
    email: '',
  });
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    showPart: false,
    email: '',
    degree: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempForm = { ...form };
    const tempErrors = { ...errors };
    if (name === 'email' && !validateEmail(value)) {
      tempErrors[name] = 'Please insert a valid email';
      setError(tempErrors);
    }
    errors[name] = '';
    tempForm[name] = value;
    setForm(tempForm);
  };

  const handleChangeCheckbox = ({ target }) => {
    const { name, checked } = target;
    const tempForm = { ...form };
    tempForm[name] = checked;
    setForm(tempForm);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setEditing(false);
    if (!editing) onSubmitUser(form);
  };

  return (
    <form onSubmit={handleSubmit} className='col-9 user-creation-form mb-5'>
      <ul className='p-0 mb-0'>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Name:</label>
          </div>
          <EditingInput
            type='text'
            name='firstName'
            value={form.firstName}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter the Name'
            required
            editing={editing}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Surname:</label>
          </div>
          <EditingInput
            type='text'
            name='lastName'
            value={form.lastName}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter the Surname'
            required
            editing={editing}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Email:</label>
          </div>
          <div className='col p-0'>
            <EditingInput
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter the Email'
              required
              editing={editing}
            />
            {form.email && errors.email && <p className='m-0 mt-1 text-danger'>{errors.email}</p>}
          </div>
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Degree:</label>
          </div>
          <EditingSelect
            name='degree'
            onChange={handleChange}
            value={form.degree}
            items={degrees}
            useProperty='value'
            displayProperty='name'
            required={true}
            editing={editing}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0' htmlFor='showPart'>
              Show Participant
            </label>
          </div>
          <input
            className={!editing ? 'ml-3' : ''}
            disabled={!editing}
            type='checkbox'
            name='showPart'
            checked={form.showPart}
            onChange={handleChangeCheckbox}
            id='showPart'
          />
          <span className='small ml-2 text-black-50'>( Trade Show Participants )</span>
        </li>
      </ul>
      {!editing && (
        <p className='text-right invitation-dsc mt-2 mb-0'>
          The invitation will be sent to: {form.email}
        </p>
      )}
      <div className='d-flex justify-content-end mt-2'>
        {!editing && (
          <Button className='btn-blue mr-2 mt-1 px-4' onClick={() => setEditing(!editing)}>
            Edit
          </Button>
        )}
        <ButtonLine className='btn btn-blue px-4' type='submit' disabled={fetch}>
          {editing ? 'Next' : 'Invite'}
        </ButtonLine>
      </div>
    </form>
  );
};

export default UserCreationForm;
