/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { formatDate } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import removeIcon from 'assets/bin.svg';

const headers = [
  {
    name: 'License Key',
    className: 'col-3',
    key: 'licenseKey',
  },
  {
    name: 'Entity',
    className: 'col-2',
    key: 'entity',
  },
  {
    name: 'Subscription Plan',
    className: 'col-3',
    key: 'subscription',
  },
  {
    name: 'Created Date',
    className: 'col-1 min-120 text-nowrap',
    key: 'addedAt',
  },
  // {
  //   name: 'Max Use',
  //   className: 'col-1',
  //   key: 'maxUsageCount',
  // },
  {
    name: 'Used',
    className: 'col-1',
    key: 'used',
  },
  {
    name: '',
    className: 'col-2',
    key: '',
  },
];

const LicensesLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col-3 align-items-center'>{item.licenseKey || '-'}</div>
                <div className='d-flex col-2 align-items-center'>
                  {item.entity ? item.entity.name : '-'}
                </div>
                <div className='d-flex col-3 align-items-center'>
                  {item.subscription && item.subscription.title}
                </div>
                <div className='d-flex col-1 min-120 align-items-center'>
                  {formatDate(item.addedAt) || '-'}
                </div>
                {/* <div className='d-flex col-1 align-items-center justify-content-center px-1 pl-2'>
                  {item.maxUsageCount || '-'}
                </div> */}
                <div className='d-flex col-1 align-items-center justify-content-center px-1 pl-2'>
                  {item.used ? 'Yes' : 'No'}
                </div>
                <div className='d-flex align-items-center p-2 justify-content-end w-100'>
                  <Link className='more-btn mr-2 px-2' to={`/license/${item.id}`}>
                    More <IconRight />
                  </Link>
                  <button
                    onClick={onItemRemoveConfirm.bind(null, item)}
                    disabled={onItemRemoveDisabled}
                    type='button'
                    className='btn btn-sm btn-red list-remove-btn'
                  >
                    <img width='15' src={removeIcon} alt='icon' />
                  </button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LicensesLists;
