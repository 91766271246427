import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';
import ExportList from 'shared/components/ExportList';

const filters = [
  { name: 'All Courses', path: '/courses/all' },
  { name: 'Active Courses', path: '/courses/1' },
  { name: 'Used', path: '/courses/all/1' },
];

const exportData = [
  { name: 'Enrolled Users (PDF)', id: 1 },
  { name: 'All Courses (PDF)', id: 2 },
  { name: 'All Courses (CSV)', id: 3 },
];

const CoursesHeader = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('courses_create') && (
          <Link className='btn btn-invite' to='/courses/new'>
            + Add New Course
          </Link>
        )}
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink key={item.path} exact className='mx-3' to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className='d-flex'>
        <ExportList data={exportData} type='courses' />
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default CoursesHeader;
