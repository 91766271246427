import { lessonTypes } from 'app/Main/configs';
import React from 'react';
import { useSnackbar } from 'notistack';
import attachIcon from 'assets/attach.svg';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import TagsSelect from 'shared/components/TagsSelect';

const LessonGeneralStep = ({ form, setForm, data, setData, isInUse }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setData({ ...data, lessonImage: res.data.data });
      setForm({ ...form, uploadImageId: res.data.data.id });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonImg = data.lessonImage && data.lessonImage.url;

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Lesson General Settings</h6>
      <div className='col-7 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Name*:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Image:</label>
            </div>
            <div className='upload-file-form w-100 overflow-hidden'>
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill hide'
                onChange={onImageUpload}
              />
              <label
                htmlFor='file'
                className={`d-flex justify-content-between align-items-center text-nowrap ${
                  lessonImg ? 'bg-transparent pl-0' : ''
                }`}
              >
                {lessonImg && <img height='40' className='mr-2' src={lessonImg} alt='img' />}
                {!lessonImg && 'Upload Image'}
                <div>
                  {lessonImg && 'Change Image'}
                  <img src={attachIcon} className='ml-2' alt='icon' />
                </div>
              </label>
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Type*:</label>
            </div>
            <EditingSelect
              name='contentType'
              onChange={handleChange}
              value={form.contentType}
              items={lessonTypes}
              useProperty='id'
              displayProperty='name'
              required={true}
              editing={!isInUse}
              hideDefault={true}
              valueClassName='pl-0'
            />
          </li>
          <li className='d-flex'>
            <div className='col-3 pl-0 pt-1'>
              <label className='m-0'>Short Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Description'
              required
              editing={true}
              access={true}
              maxLength='2000'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Nickname:</label>
            </div>
            <EditingInput
              type='text'
              name='nickName'
              value={form.nickName}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Nickname'
              editing={true}
              access={true}
              maxLength='255'
            />
          </li>
          {/* <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Tags:</label>
            </div>
            <TagsSelect value={data.tags} onChange={tags => setData({ ...data, tags })} />
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default LessonGeneralStep;
