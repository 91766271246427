import React from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { hasAccess } from 'utils/permissionHelper';
import Loading from 'shared/components/Loading';
import removeIcon from 'assets/bin.svg';

const headers = [
  {
    name: 'Subscription plan title',
    className: 'col-3 d-flex justify-content-between align-items-center',
    key: 'title',
  },
  {
    name: 'Status',
    className: 'col-1',
    key: 'subscriptionStatus',
  },
  {
    name: 'Content',
    className: 'col-1',
    key: 'totalCourses',
  },
  {
    name: 'Duration',
    className: 'col-1',
    key: 'duration',
  },
  {
    name: 'Validity',
    className: 'col-1',
    key: 'courseDuration',
  },
  {
    name: 'Type',
    className: 'col-1',
    key: 'subscriptionType',
  },
  {
    name: 'Use',
    className: 'col-1',
    key: 'totalUsers',
  },
  {
    name: 'Faculty',
    className: 'col-1',
    key: 'totalAuthors',
  },
];

const subsTypes = {
  1: 'No Key Required',
  2: 'Paid',
  3: 'Key Required',
};

const SubscriptionsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const rowClass = 'col-1 d-flex align-items-center';

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => (
            <li key={item.id} className='d-flex list-item h-not-set'>
              <div className='d-flex col-3 align-items-center'>
                <img src={item.image} alt={item.firstname} className='user-image no-radius mr-2' />
                <div>
                  <b>{item.name}</b>
                </div>
              </div>
              <div className={rowClass}>{item.status === 1 ? 'Active' : 'Inactive'}</div>
              <div className={`${rowClass} text-nowrap`}>{`${item.totalCourses} courses`}</div>
              <div className={rowClass}>{item.time ? `${item.time} hours` : '-'}</div>
              <div className={`${rowClass} text-nowrap`}>
                {item.days ? `${item.days} days` : '-'}
              </div>
              <div className='col-1 d-flex align-items-center'>{subsTypes[item.type] || '-'}</div>
              <div className={rowClass}>{`${item.totalUsers} users`}</div>
              <div className={`${rowClass} text-nowrap`}>{`${item.totalAuthors} Faculty`}</div>
              <div className='d-flex align-items-center p-2 justify-content-end w-100'>
                {hasAccess('subscription_view') && (
                  <Link className='more-btn small mr-3' to={`/subscription/${item.id}`}>
                    Edit <IconRight />
                  </Link>
                )}

                <Link className='more-btn small mr-3' to={`/subscription/${item.id}/view`}>
                  View <IconRight />
                </Link>
                <button
                  onClick={onItemRemoveConfirm.bind(null, item)}
                  disabled={onItemRemoveDisabled}
                  type='button'
                  className='btn btn-sm btn-red list-remove-btn'
                >
                  <img width='15' src={removeIcon} alt='icon' />
                </button>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
