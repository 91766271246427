import React, { useState } from 'react';

const isRequired = key => TypeError(`the ${key} prop is required!`);

const Accordion = ({
  data = [],
  HeaderComponent = isRequired('HeaderComponent'),
  BodyComponent = isRequired('BodyComponent'),
  childrenKey = isRequired('childrenKey'),
  childrenClassName = 'mb-4',
  className = '',
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = index => setActiveIndex(activeIndex === index ? null : index);

  return (
    <div className={`accordion ${className}`}>
      {data.length > 0 &&
        data.map((group, index) => (
          <ul className='accordion-item' key={`plan-${group.id || index}`}>
            <li
              className={`item-header ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleAccordion(index)}
              role='presentation'
            >
              <HeaderComponent data={group} />
            </li>
            {group[childrenKey].length > 0 && activeIndex === index && (
              <li className='item-content'>
                <ul className='accordion-item'>
                  {group[childrenKey].map((item, itemIndex) => (
                    <li key={`item-${item.id}`} className={childrenClassName}>
                      <BodyComponent data={item} />
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        ))}
    </div>
  );
};

export default Accordion;
