import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import CreationForm from './components/CreationForm';
import Header from './components/Header';
import Loading from 'shared/components/Loading';

const AuthorNew = ({ history, match }) => {
  const isUpdate = !!match.params.id;
  const multiItem = () => ({ title: '', info: '' });
  const initialMultiItem = Array.from({ length: 1 }, () => multiItem());
  const intialMock = {
    name: '',
    surname: '',
    profession: '',
    authorEducations: [...initialMultiItem],
    authorExperience: [...initialMultiItem],
    bio: '',
    info: '',
    imageUrl: '',
  };

  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState();
  const [author, setAuthor] = useState();

  const handleFormSubmission = async data => {
    const path = isUpdate ? '/authors/updateauthor' : '/authors/addauthor';
    const body = isUpdate ? { authorProfileId: author.id, ...data } : data;
    try {
      setFetch(true);
      await Api.post(path, body);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push('/authors/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetch(false);
    }
  };

  const getUserData = async id => {
    if (!id) {
      setAuthor(intialMock);
    } else {
      const res = await Api.get(`/authors/getauthor/${id}`);
      setAuthor(res.data.data);
    }
  };

  useEffect(() => {
    getUserData(match.params.id);
    //eslint-disable-next-line
  }, [match.params.id]);

  if (!author) return <Loading className='mt-5' />;

  return (
    <div>
      <Header isUpdate={isUpdate} author={author} />
      <div className='col mt-2 pb-5 has-header'>
        <h5 className='p-3 color-blue'>{isUpdate ? 'Faculty Details' : 'Add New Faculty'}</h5>
        <CreationForm fetch={fetch} initialData={author} onSubmitUser={handleFormSubmission} />
      </div>
    </div>
  );
};

export default AuthorNew;
