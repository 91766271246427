import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconRight, IconClose } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

const UserDeactivateModal = ({ user, close }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fecth, setFecth] = useState(false);
  const [send, setSend] = useState(false);
  const [message, setMessage] = useState('');

  const onUserDeactivate = async () => {
    try {
      setFecth(true);
      const { data } = await Api.post(`/admin/deactivateuser/${user.id}`, { message });
      enqueueSnackbar(data.message, { variant: 'success' });
      close();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFecth(false);
    }
  };

  return (
    <div className='modal-box deactivate-modal d-flex flex-column'>
      <div className='align-items-start d-flex header justify-content-between'>
        <div>User Deactivate</div>
        <div onClick={close} tabIndex='-1' role='button' className='mt-1 pointer'>
          <IconClose />
        </div>
      </div>
      <div className='content flex-fill'>
        <div className='d-flex align-items-center my-2'>
          <input
            id='send'
            name='send'
            type='checkbox'
            checked={send}
            onChange={e => setSend(item => !item)}
          />
          <label className='m-0 ml-2 pointer' htmlFor='send'>
            <b>Send a message to email and deactivate</b>
          </label>
        </div>
        <p className='small'>Sending to {user.email}</p>
        <textarea
          disabled={!send}
          className='form-control'
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder='Enter message please'
        />
        <b className='mb-2 mt-3 d-block'>Warning:</b>
        <p className='warning-info'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
          viverra maecenas accumsan lacus vel facilisis.
        </p>
      </div>
      <div className='footer text-right'>
        <button className='link-color p-0 btn mt-3' onClick={onUserDeactivate} disabled={fecth}>
          <b>
            {send ? 'Send and ' : ''}Deactivate <IconRight />
          </b>
        </button>
      </div>
    </div>
  );
};

export default UserDeactivateModal;
