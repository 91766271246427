import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import UsersHeader from './components/UsersHeader';
import ReactPaginate from 'react-paginate';
import UsersLists from './components/UsersLists';
import { getError } from 'utils/appHelpers';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';

const Users = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { filter } = match.params;
  const [fetch, setFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [users, setUsers] = useState();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const [reqParams, setReqParams] = useState({
    order: true,
    orderKey: 'fullName',
    limit: 8,
  });

  const debouncedSearch = useDebounce(search, 500);

  if (!filter) history.push('users/all');

  const getUsersList = async toPage => {
    try {
      setFetch(true);
      const status = filter === 'all' ? null : filter;
      const fetch_all = Number(status) === 4 || filter === 'all' || filter == null;
      const params = {
        ...reqParams,
        status,
        fetch_all,
        page: toPage + 1 || 1,
        search: debouncedSearch,
      };
      const { data } = await Api.get('/admin/getusers', { params });
      if (!toPage) setPage(0);
      setPageCount(Math.ceil(data.data.count / 8));
      setUsers(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
    getUsersList(selected);
  };

  const handleSearchChange = text => {
    // setReqParams(items => ({ ...items, page: 0 }));
    setSearch(text);
  };

  useEffect(() => {
    if (users) {
      setReqParams(items => ({ ...items, page: 0 }));
      setPageCount(0);
    }
    //eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    getUsersList();
    //eslint-disable-next-line
  }, [debouncedSearch, reqParams]);

  return (
    <div>
      <UsersHeader onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <UsersLists
            users={users}
            order={reqParams}
            status={filter}
            changeOrder={setReqParams}
            fetch={fetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            containerClassName={'pagination show-arrow'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
