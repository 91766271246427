import React from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';

const DragList = ({ onDragEnd, items, children, className, rowClassName, disabled }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} className={className} ref={provided.innerRef}>
            {items &&
              items.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={String(index + 1)}
                  index={index}
                  isDragDisabled={disabled}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={rowClassName || 'mb-3'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {children(item, index, snapshot)}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragList;
