import React, { useState, useEffect } from 'react';
import SuccessInfo from '../../components/SuccessInfo';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { ReactComponent as IconLogo } from 'assets/ACE-logo-blue.svg';
import FormStep from './components/FormStep';
import { Link } from 'react-router-dom';

const Register = ({ history, match }) => {
  const [error, setError] = useState();
  const [fetch, setFetch] = useState(false);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState();
  const regCode = match.params.code;

  const successInfo = {
    title: 'Success!',
    subTitle: 'You are successfully registered',
    className: 'col-12 pt-5',
  };

  const handleFormSubmit = async data => {
    setFetch(true);
    try {
      await Api.post('/auth/register', data);
      handleStepChange(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const handleStepChange = bool => {
    if (bool) {
      setStep(step + 1);
      return;
    }
    step === 1 ? history.push('/login') : setStep(step - 1);
  };

  const getUserdata = async code => {
    const { data } = await Api.get(`/auth/getinviteuser/${code}`);
    setUser({
      fname: data.data.firstName,
      lname: data.data.lastName,
      email: data.data.email,
    });
  };

  useEffect(() => {
    if (regCode) getUserdata(regCode);
  }, [regCode]);

  return (
    <div className='align-items-center auth-block large d-flex py-5 position-relative'>
      <div className='text-center'>
        <Link to='/login'>
          <IconLogo />
        </Link>
      </div>
      {step === 1 && user && (
        <FormStep
          formSubmit={handleFormSubmit}
          user={user}
          error={error}
          fetch={fetch}
          regCode={regCode}
        />
      )}
      {step === 2 && (
        <div className='justify-content-center mt-5 pt-5 text-center'>
          <SuccessInfo {...successInfo} />
        </div>
      )}
    </div>
  );
};

export default Register;
