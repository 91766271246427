import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { getError } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import { Api } from 'utils/connectors';
import Button from 'shared/components/Button';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { IconEdit } from 'shared/components/Icons';
import LinkIcon from 'shared/components/LinkIcon';
import { useSelector } from 'react-redux';

const LineKeyValue = ({ field1, field2, field3, className }) => (
  <div className='d-flex border-bottom py-2 mb-2'>
    <div className={`col text-left pl-0 ${className || ''}`}>{field1}</div>
    <div className={`col text-left pl-0 ${className || ''}`}>{field2}</div>
    {field3 !== undefined && (
      <div className={`col text-left pl-0 ${className || ''}`}>{field3}</div>
    )}
  </div>
);

const EntitiesView = ({ match, history }) => {
  const account = useSelector(state => state.account);
  const id = match.params.id ? match.params.id : account.entity ? account.entity.id : '';
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [formFetch, setFormFetch] = useState(false);
  const [editing, setEditing] = useState(false);
  const [entity, setEntity] = useState();
  const [form, setForm] = useState({
    uploadImageId: 0,
    originId: Number(id),
    name: '',
    address: '',
  });

  const getEntityData = async id => {
    if (!id) return;
    try {
      setFetch(true);
      const { data } = await Api.get(`/entity/getentity/${id}`);
      if (data.data.managers && data.data.managers.length) {
        data.data.members = [...data.data.managers, ...(data.data.members || [])];
      }
      setEntity(data.data);
      setFetch(false);
      setForm({ ...form, name: data.data.name, address: data.data.address });
    } catch (err) {
      history.push(`/entities`);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const { data } = await Api.post('/upload', formData);
      await Api.post('/entity/update', { ...form, uploadImageId: data.data.id });
      getEntityData(id);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFormFetch(true);
      await Api.post('/entity/update', form);
      setEditing(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFormFetch(false);
    }
  };

  useEffect(() => {
    getEntityData(id);
    //eslint-disable-next-line
  }, [id])

  if (fetch || !entity) return <Loading className='w-100 mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/entities'>Entities</Link>
          <span className='mx-2'>/</span>
          <span>Entity Profile</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <div className='d-flex'>
          <label htmlFor='file' className='col-3 entity-image-upload pointer py-2 text-center'>
            {entity.imageUrl && <img height='100%' src={entity.imageUrl} alt='entity' />}
            {!entity.imageUrl && (
              <span className='d-flex align-items-center h-100 justify-content-center'>
                Upload Image
              </span>
            )}
            <input id='file' type='file' className='hide' onChange={onImageUpload} />
          </label>
          <form onSubmit={handleSubmit} className='col-7 user-creation-form'>
            <ul className='p-0 mb-0'>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Name:</label>
                </div>
                <EditingInput
                  type='text'
                  name='name'
                  value={form.name}
                  onChange={handleChange}
                  className='p-0'
                  placeholder='Enter Name'
                  required
                  editing={editing}
                />
              </li>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Address:</label>
                </div>
                <EditingInput
                  type='text'
                  name='address'
                  value={form.address}
                  onChange={handleChange}
                  className='p-0'
                  placeholder='Enter Address'
                  required
                  editing={editing}
                />
              </li>
            </ul>
            <div className='d-flex justify-content-end mt-2'>
              <Button
                className='btn-blue mt-1 px-4 fz-12 font-weight-bold'
                onClick={() => setEditing(!editing)}
              >
                {!editing ? (
                  <>
                    <IconEdit className='mr-1' /> Edit
                  </>
                ) : (
                  'Close'
                )}
              </Button>
              {editing && (
                <Button
                  className='btn btn-blue ml-2 px-4 fz-12 font-weight-bold'
                  type='submit'
                  disabled={formFetch}
                >
                  Update
                </Button>
              )}
            </div>
          </form>
        </div>
        <div className='col-10 pl-0 mt-5'>
          <div className='entity-list-block blue-box'>
            <div className='text-right'>
              {(!entity.members || !entity.members.length) && (
                <div className='text-left border-bottom pb-1 mb-2'>No Members</div>
              )}
              {entity.members && !!entity.members.length && (
                <>
                  <LineKeyValue
                    // field1='Full Name'
                    field1='Email'
                    field2='License Keys'
                    className='font-weight-bold'
                  />
                  {entity.members.map((item, i) => (
                    <LineKeyValue
                      key={i}
                      // field1={`${item.firstName || ''} ${item.lastName || ''}`}
                      field1={item.email}
                      field2={item.licenses && item.licenses.length}
                    />
                  ))}
                </>
              )}
              <LinkIcon className='link font-weight-bold' to={`/entity/${id}/users`}>
                Entity Members
              </LinkIcon>
            </div>
          </div>
          <div className='entity-list-block blue-box mt-4'>
            <div className='text-right'>
              {(!entity.licenses || !entity.licenses.length) && (
                <div className='text-left border-bottom pb-1 mb-2'>No License Keys</div>
              )}
              {entity.licenses && !!entity.licenses.length && (
                <>
                  <LineKeyValue
                    field1='License Key'
                    field2='Subscription'
                    className='font-weight-bold'
                  />
                  {entity.licenses.map((item, i) => (
                    <LineKeyValue
                      key={i}
                      field1={item.licenseKey}
                      field2={item.subscription ? item.subscription.title : '-'}
                    />
                  ))}
                </>
              )}
              <LinkIcon className='link font-weight-bold' to={`/licenses/all/${id}`}>
                Manage License Keys
              </LinkIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntitiesView;
