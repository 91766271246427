import React from 'react';
import moveIcon from 'assets/move.svg';
import removeIcon from 'assets/bin.svg';
import { IconCheckBold } from './Icons';

const DragItem = ({
  children,
  className,
  onItemRemove,
  onItemCheck,
  isItemCheck,
  onItemRemoveDisabled,
  disabled,
}) => {
  return (
    <div className={`d-flex drag-item justify-content-between align-items-center ${className}`}>
      {children}
      <div className={`action-buttons d-flex ${onItemRemove ? 'has-remove' : ''}`}>
        <div className={`btn btn-drag ${disabled ? 'disabled' : ''}`}>
          <img src={moveIcon} alt='icon' />
        </div>
        {onItemCheck && (
          <button
            disabled={disabled}
            onClick={onItemCheck}
            type='button'
            className={`btn btn-check ml-4`}
          >
            <IconCheckBold className={isItemCheck ? 'opc-full' : 'opc-2'} />
          </button>
        )}
        {onItemRemove && (
          <button
            onClick={onItemRemove}
            disabled={onItemRemoveDisabled}
            type='button'
            className={`btn btn-remove ${onItemCheck ? 'ml-2' : 'ml-3'}`}
          >
            <img src={removeIcon} alt='icon' />
          </button>
        )}
      </div>
    </div>
  );
};

export default DragItem;
