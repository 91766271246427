import React from 'react';

const InputGroup = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  type,
  disabled,
  required,
  icon,
  className = '',
  hasError,
  leftPlaceholder,
  maxLength,
}) => (
  <div
    className={`input-group ${className || ''} ${hasError ? 'has-error' : ''} ${
      !icon ? 'no-icon' : ''
    } ${leftPlaceholder ? 'has-placeholder' : ''}`}
  >
    {icon && <div className='icon-area'>{icon}</div>}
    {leftPlaceholder && <span className='fixed-placeholder'>{leftPlaceholder}</span>}
    <input
      type={type}
      value={value}
      id={name}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      aria-describedby=''
      placeholder={placeholder}
      autoComplete='off'
      disabled={disabled}
      required={required}
      className='flex-fill'
      maxLength={maxLength}
    />
  </div>
);

export default InputGroup;
