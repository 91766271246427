export const roles = [
  { name: 'Creator', value: '2' },
  { name: 'Viewer', value: '3' },
  { name: 'Entity Manager', value: '4' },
  { name: 'KOL', value: '6' },
];

export const rolesObj = {
  1: 'Super Admin',
  2: 'Creator',
  3: 'Viewer',
  4: 'Entity Manager',
  6: 'KOL',
};
