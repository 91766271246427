import React from 'react';
import { Link } from 'react-router-dom';

const CourseUsersHeader = ({ course }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/courses'>All Courses</Link>
        <span className='mx-2'>/</span>
        <Link to={`/course/${course.id}`}>{course.title}</Link>
        <span className='mx-2'>/</span>
        <span>Engaged Users</span>
      </div>
    </div>
  );
};

export default CourseUsersHeader;
