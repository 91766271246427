import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import RadioBox from 'shared/components/RadioBox';

const NotificationsManage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [items, setItems] = useState([]);

  const getItems = async () => {
    try {
      const { data } = await Api.get('/common/configs/admin-email');
      setItems(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const updateItems = async () => {
    try {
      setFetch(true);
      await Api.post('/common/configs/admin', { email: items });
      await getItems();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const handleItemChange = ({ target }) => {
    const { value, name } = target;
    const index = items.findIndex(i => i.name === name);
    const tempData = [...items];
    tempData[index] = { ...tempData[index], value: value === 'true' ? 'false' : 'true' };
    setItems(tempData);
  };

  useEffect(() => {
    getItems();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='breadcrumbs users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <Link to='/profile'>Admin Profile</Link>
          <span className='mx-2'>/</span>
          <span>Email Notifications</span>
        </div>
      </div>
      <div className='col has-header py-2'>
        {items &&
          items.map((item, i) => {
            return (
              <RadioBox
                className='mb-2'
                key={i}
                name={item.name}
                value={item.value}
                label={item.title}
                checked={item.value === 'true'}
                onChange={handleItemChange}
              />
            );
          })}
        <div className='text-right col pt-2'>
          <button className='btn btn-primary px-5' onClick={updateItems} disabled={fetch}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default NotificationsManage;
