import React from 'react';
import { IconArrowLeft } from './Icons';

const BackButton = ({ history, route, className }) => {
  const handleBackClick = () => {
    if (route) history.push(route);
    else history.goBack();
  };

  return (
    <div
      className={`back-button pointer ${className || ''}`}
      onClick={handleBackClick}
      role='button'
      tabIndex='-1'
    >
      <IconArrowLeft />
    </div>
  );
};

export default BackButton;
