import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import AdminHeader from './components/AdminHeader';
import AdminInfo from './components/AdminInfo';
import Loading from 'shared/components/Loading';

const AdminView = ({ match }) => {
  const [user, setUser] = useState();
  const isInvited = match.params.type === 'invited';

  const getUserData = async () => {
    const { id } = match.params;
    const path = isInvited ? '/admin/getinvitationbyid' : '/auth/getuserbyid';
    const res = await Api.get(`${path}/${id}`);
    setUser(res.data.data);
  };

  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, [match.params.id]);

  if (!user) return <Loading className='mt-5' />;

  return (
    <div>
      <AdminHeader user={user} />
      <div className='col-12 py-4 has-header'>
        <AdminInfo user={user} isInvited={isInvited} getUserData={getUserData} />
      </div>
    </div>
  );
};

export default AdminView;
