import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Loading from 'shared/components/Loading';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Button from 'shared/components/Button';
import InputGroup from 'shared/components/InputGroup';
import { Link } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const editorConfiguration = {
  removePlugins: ['ImageUpload'],
};

const SystemEmailEdit = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { type } = match.params;
  const [data, setData] = useState();
  const [fetch, setFetch] = useState(false);

  const adminDomain = window.location.origin;

  const getEmailTemp = async () => {
    try {
      const { data } = await Api.get(`/common/email-template/${type}`);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      await Api.post(`/common/email-template`, data);
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getEmailTemp();
    //eslint-disable-next-line
  }, [type]);

  if (!data) return <Loading className='mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/support/emails'>System Emails</Link>
          <span className='mx-2'>/</span>
          <span>Edit "{data.title}" Email</span>
        </div>
      </div>
      <div className='py-3 has-header'>
        <div className='row col-12'>
          <form onSubmit={handleSubmit} className='col-9 user-creation-form'>
            <ul className='p-0 mb-0'>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Subject:</label>
                </div>
                <InputGroup
                  type={type}
                  name='subject'
                  onChange={({ target }) => setData({ ...data, subject: target.value })}
                  placeholder='Subject'
                  value={data.subject}
                  required
                />
              </li>
            </ul>
            <div className='email-editor'>
              <CKEditor
                editor={ClassicEditor}
                data={data.body}
                config={editorConfiguration}
                onChange={(event, editor) => {
                  setData({ ...data, body: editor.getData() });
                }}
              />
            </div>
            <div className='d-flex justify-content-end mt-2'>
              <Button type='submit' className='btn-blue mr-2 mt-1 px-4' disabled={fetch}>
                Update
              </Button>
            </div>
          </form>
          <div className='col-3 email-edit-info mt-2 pt-5 px-0'>
            <p className='info-title'>Email Variables</p>
            <p>
              Email variables are structured strings which email composer replaces with matching
              values at the email sending time.
            </p>
            <p>
              The email composer recognizes variables by special character %%. The string between
              special characters are taken as variable name, like %%NAME%% will be replaced with the
              user’s name at email sending.
            </p>
            <p className='info-title'>Variables that can be used in email body:</p>
            <p>
              <span className='bold'>USERNAME</span> - The name of registered user
              <br /> <span>Example:</span> <br />
              Customized email - Hello dear Dr.<span className='bold'>%%LASTNAME%%</span> <br />
              Received email text - Hello dear <span>Dr.Smith</span>
            </p>
            <p>
              <span className='bold'>LASTNAME</span> - The lastname of registered user
              <br /> <span>Example:</span> <br />
              Customized email - Hello dear Dr.<span className='bold'>%%LASTNAME%%</span> <br />
              Received email text - Hello dear <span>Dr.Smith</span>
            </p>
            <p>
              <span className='bold'>FORGOTLINK</span> - Password reset link
              <br /> <span>Example:</span> <br />
              Customized email - Visit following URL to activate your account.{' '}
              <span className='bold'>%%FORGOTLINK%%</span> <br />
              Received email text - Visit following URL to activate your account{' '}
              <span>/taMc1kmH6mi5Qgx4TsBdVW2Hgw4</span>
            </p>
            <p>
              <span className='bold'>EMAIL</span> - A account that you use when registering.
              <br /> <span>Example:</span> <br />
              Customized email - Message sent to. <span className='bold'>%%EMAIL%%</span> <br />
              Received email text - Message sent to <span>jsmith@example.com</span>
            </p>
            <p>
              <span className='bold'>REGISTERLINK</span> - Registration link.
              <br /> <span>Example:</span> <br />
              Customized email - To register now, please visit the following link:{' '}
              <span className='bold'>%%REGISTERLINK%%</span> <br />
              Received email text - To register now, please visit the following link:{' '}
              <span>{adminDomain}/register/c642</span>
            </p>
            <p>
              <span className='bold'>PASSWORD</span> - A string of characters that allows access to
              a computer system or service.
              <br /> <span>Example:</span> <br />
              Customized email - Please follow this (link at the bottom) to register in ACE{' '}
              <span className='bold'>%%PASSWORD%%</span> <br />
              Received email text - Please follow this (link at the bottom) to register in ACE{' '}
              <span>{adminDomain}/register/c6421</span>
            </p>
            <p>
              <span className='bold'>INVITATIONLINK</span> - Invitation link.
              <br /> <span>Example:</span> <br />
              Customized email - Click on this link to accept the invitation:{' '}
              <span className='bold'>%%INVITATIONLINK%%</span> <br />
              Received email text - Click on this link to accept the invitation:{' '}
              <span>{adminDomain}/register/c642</span>
            </p>
            <p>
              <span className='bold'>LOGIN</span> - A name that you enter in order to be able to use
              a computer system.
              <br /> <span>Example:</span> <br />
              Customized email - Message sent to <span className='bold'>%%LOGIN%%</span> <br />
              Received email text - Message sent to <span>jsmith@example.com</span>
            </p>
            <p>
              <span className='bold'>USERNAME</span> - A name that you enter in the registration
              process
              <br /> <span>Example:</span> <br />
              Customized email - <span className='bold'>%%USERNAME%%</span> has registered their
              account <br />
              Received email text - <span>jsmith</span> has registered their account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemEmailEdit;
