import React from 'react';
import InputGroup from 'shared/components/InputGroup';

const EditingInput = ({
  type,
  name,
  value,
  placeholder,
  required,
  onChange,
  className,
  editing,
  hasError,
  disabled,
  forceValue,
  maxLength,
}) =>
  editing ? (
    <InputGroup
      type={type}
      name={name}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      value={value}
      hasError={hasError}
      required={required}
      disabled={disabled}
      forceValue={forceValue}
      maxLength={maxLength}
    />
  ) : (
    <span className='result'>{forceValue || value}</span>
  );

export default EditingInput;
