import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import SubsGeneralStep from './components/SubsGeneralStep';
import SubsCompleteStep from './components/SubsCompleteStep';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import SubsCoursesStep from './components/SubsCoursesStep';
import Loading from 'shared/components/Loading';

const SubscriptionNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [isInUse, setIsInUse] = useState(false);
  const [course, setSubs] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    activeCases: [],
  });
  const [form, setForm] = useState({
    title: '',
    description: '',
    subscriptionType: 1,
    courseDuration: '',
    planDuration: '',
    uploadImageId: 0,
    expirable: 1,
  });

  const steps = [SubsGeneralStep, SubsCoursesStep, SubsCompleteStep];

  const constructFormData = form => {
    form.courses = data.activeCourses.map(i => i.courseId);
    if (!form.expirable) {
      delete form.courseDuration;
    }
    return form;
  };

  const onSubsSave = async () => {
    try {
      setFetching(true);
      const body = constructFormData(form);
      const endpoint = id ? '/wizard/subscription/update' : '/wizard/subscription/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar('Subscription successfully created', { variant: 'success' });
      history.push('/subscriptions/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setInitialCourses = data => {
    if (!data) return [];
    return data.map(({ course: item }) => {
      item.courseId = item.id;
      return item;
    });
  };

  const setSubsInitialData = subs => {
    setForm({
      subscriptionType: subs.subscriptionType || '',
      title: subs.title || '',
      description: subs.description || '',
      courseDuration: subs.courseDuration || '',
      planDuration: subs.planDuration || '',
      expirable: subs.expirable || false,
    });
    setData({
      subsImage: {
        url: subs.imageUrl,
      },
      activeCourses: setInitialCourses(subs.courses),
    });
  };

  const getSubsData = async id => {
    try {
      const { data } = await Api.get(`/wizard/subscription/${id}`);
      const { subscription, inUse } = data.data;
      setIsInUse(inUse);
      setSubs(subscription);
      setSubsInitialData(subscription);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/subscriptions/all');
    }
  };

  useEffect(() => {
    if (id) getSubsData(id);
    //eslint-disable-next-line
    }, [id]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && !course) return <Loading className='mt-5' />;

  return (
    <div className='lesson-creation'>
      <Header steps={steps} activeStep={activeStep} />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onSubsSave={onSubsSave}
            fetching={fetching}
            isInUse={!!isInUse}
          />
        </div>
        <FooterButtons
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
        />
      </form>
    </div>
  );
};

export default SubscriptionNew;
