import React from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { getImageUrl } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';

const headers = [
  {
    name: 'Name',
    className: 'col-3',
    key: 'name',
  },
  {
    name: 'Bio',
    className: 'col-4',
    key: 'bio',
  },
  {
    name: 'Info',
    className: 'col-4',
    key: 'info',
  },
  {
    name: '',
    className: 'col-1',
    key: '',
  },
];

const Lists = ({ users, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!users || !users.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map(item => {
            return (
              <li key={item.id} className='d-flex align-items-center list-item'>
                <div className='d-flex col-3 align-items-center'>
                  <img
                    src={getImageUrl(item.imageUrl)}
                    alt={item.name}
                    className='user-image mr-2'
                  />
                  <div>
                    <b>
                      {item.name} {item.surname}
                    </b>
                    <br />
                    <span className='small text-secondary'>{item.profession}</span>
                  </div>
                </div>
                <div className='col-4 text-truncate' title={item.bio}>
                  {item.bio}
                </div>
                <div className='col-4 text-truncate' title={item.info}>
                  {item.info}
                </div>
                <div className='d-flex justify-content-end p-2 w-100'>
                  <Link className='more-btn w-25 small' to={`/author/${item.id}`}>
                    More <IconRight />
                  </Link>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
