import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import ButtonLine from 'shared/components/ButtonLine';

const FormStep = ({ formSubmit, user, fetch, error, regCode }) => {
  const [info, setInfo] = useState({
    password: '',
    rpassword: '',
    date: '',
    ...user,
  });

  const passwordMatch = info.password === info.rpassword;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...info };
    tempAuth[name] = value;
    setInfo(tempAuth);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const body = {
      regCode,
      password: info.password,
      firstname: info.fname,
      lastname: info.lname,
      dateOfBirth: info.date,
    };
    formSubmit(body);
  };

  return (
    <form onSubmit={handleFormSubmit} className='mt-5'>
      <div className='d-flex mb-4'>
        <InputGroup
          type='text'
          name='fname'
          onChange={handleChange}
          className='mr-3'
          placeholder='First Name*'
          value={info.fname}
          required
        />
        <InputGroup
          type='text'
          name='lname'
          className='ml-3'
          onChange={handleChange}
          placeholder='Last Name*'
          value={info.lname}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='email'
          name='email'
          onChange={handleChange}
          className='mr-3'
          placeholder='Email*'
          value={info.email}
          disabled={user.email}
          required
        />
        <InputGroup
          type='date'
          name='date'
          onChange={handleChange}
          className='ml-3'
          leftPlaceholder='Date of Birth'
          value={info.date}
          disabled={user.date}
          required
        />
      </div>
      <div className='d-flex mb-4'>
        <InputGroup
          type='password'
          name='password'
          onChange={handleChange}
          className='mr-3'
          placeholder='Password'
          value={info.password}
          required
        />
        <InputGroup
          type='password'
          name='rpassword'
          onChange={handleChange}
          className='ml-3'
          placeholder='Confirm password'
          value={info.rpassword}
          required
        />
      </div>
      <div className='text-center'>
        {!passwordMatch && info.rpassword && <div className='error-text'>Passwords mismatch</div>}
        {error && <div className='error-text'>{error}</div>}
        <ButtonLine type='submit' disabled={!passwordMatch || fetch}>
          Next
        </ButtonLine>
      </div>
    </form>
  );
};

export default FormStep;
