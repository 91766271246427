import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import AuthBlock from '../components/AuthBlock';
import SuccessInfo from '../components/SuccessInfo';
import { getError } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';

const Verify = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [validCode, setValidCode] = useState(false);

  const successInfo = {
    title: 'Thank you!!',
    subTitle: 'Your email has been successfully verified',
  };

  const authOptions = {
    links: { signin: true },
  };

  const checkVerifyCode = useCallback(async () => {
    const code = match.params.code;
    try {
      await Api.get(`/auth/verify/${code}`);
      setValidCode(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/login');
    }
  }, [enqueueSnackbar, history, match.params.code]);

  useEffect(() => {
    checkVerifyCode();
  }, [checkVerifyCode]);

  if (!validCode) return <Loading className='mt-5' />;

  return <AuthBlock {...authOptions} successBlock={<SuccessInfo {...successInfo} />} />;
};

export default Verify;
