import React from 'react';
import ProgressBar from './ProgressBar';

const CourseProgress = ({ data: { name, start_date, end_date, progress } }) => (
  <div className='d-flex row align-items-end'>
    <div className={`col-4 weight-900 text-right`}>{name}</div>
    <div className='col-5'>
      <div className='d-flex justify-content-between'>
        <span className='text-date'>{start_date ? `Start ${start_date}` : 'Not Started'}</span>
        <span className='text-date'>{progress >= 100 && end_date && `End ${end_date}`}</span>
      </div>
      <ProgressBar progress={progress} />
    </div>
    <div className={`col-3 weight-900`}>{progress}%</div>
  </div>
);

export default CourseProgress;
