/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { bindDuration } from 'utils/appHelpers';
import { useHistory } from 'react-router';

const PublishRow = ({ label, data, onClick }) =>
  data || data === 0 ? (
    <p
      className={`d-flex justify-content-between mb-2 ${
        onClick ? 'link-color pointer text-underline' : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      <span>{label === 'Duration' ? bindDuration(data) : data}</span>
    </p>
  ) : null;

const PublishPreview = ({
  title,
  imageUrl,
  duration,
  courseLessons,
  courseAuthors,
  publishable,
  registeredUsers,
  courseId,
}) => {
  const history = useHistory();
  return (
    <>
      <div className='publish-block p-3 mb-3'>
        <img src={imageUrl} alt='preview' />
        <h6 className='my-3'>{title}</h6>
        <PublishRow label='Duration' data={duration} />
        <PublishRow label='Parts' data={courseLessons} />
        <PublishRow label='Faculty' data={courseAuthors} />
        <PublishRow
          label='Registered'
          data={registeredUsers || 0}
          onClick={() => history.push(`/course/${courseId}/users`)}
        />
      </div>
      {publishable && (
        <p>
          <button className='btn btn-sm btn-blue w-100'>Publish</button>
        </p>
      )}
    </>
  );
};

export default PublishPreview;
