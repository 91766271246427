import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Api } from 'utils/connectors';
import { formatDate, getError } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import { Button } from '@material-ui/core';
import { IconEdit } from 'shared/components/Icons';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { hasAccess } from 'utils/permissionHelper';

const LicensesView = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const [fetch, setFetch] = useState(false);
  const [updateFetch, setUpdateFetch] = useState(false);
  const [editing, setEditing] = useState(false);
  const [license, setLicense] = useState({});
  const [entities, setEntities] = useState([]);
  const [users, setUsers] = useState([]);
  const hasEntityAccess = hasAccess('entity_list_view');

  const getData = async id => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/entity/license/${id}`);
      const body = data.data;
      body.user = body.assigned ? body.assigned.id : '';
      body.entityName = body.entity ? body.entity.name : '';
      body.entity = body.entity ? body.entity.id : '';
      setLicense(body || {});
      setFetch(false);
    } catch (err) {
      history.push(`/licenses/all`);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async () => {
    try {
      const entitdyId = license && license.entity ? license.entity : '';
      const users = await Api.get(`/entity/assignees/${entitdyId}`);
      setUsers(users.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSelectDatas = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const entities = await Api.get(`/entity/entities`, { params });
      setEntities(entities.data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = (reset, { target }) => {
    const temp = { ...license };
    if (reset) temp.user = '';
    const { name, value } = target;
    setLicense({ ...temp, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setUpdateFetch(true);
      await Api.post(`/entity/license/${id}`, {
        entityId: license.entity ? Number(license.entity) : undefined,
        userId: license.user ? Number(license.user) : undefined,
      });
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      getData(id);
      setEditing(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUpdateFetch(false);
    }
  };

  useEffect(() => {
    getData(id);
    //eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (hasEntityAccess) getSelectDatas();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, [license.entity]);

  if (fetch || !license) return <Loading className='w-100 mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/licenses/all'>License Keys</Link>
          <span className='mx-2'>/</span>
          <span>View Key</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <form onSubmit={handleSubmit} className='col-9 p-0 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>License Key:</label>
              </div>
              <span className='result'>{license.licenseKey}</span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Subscription Plan:</label>
              </div>
              <span className='result'>
                {license.subscription ? license.subscription.title : '-'}
              </span>
            </li>
            {/* <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Number Of Maximum Assignments:</label>
              </div>
              <span className='result'>{license.maxUsageCount ? license.maxUsageCount : '-'}</span>
            </li> */}
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Date Added:</label>
              </div>
              <span className='result'>{formatDate(license.addedAt) || '-'}</span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Assigned Entity:</label>
              </div>
              <EditingSelect
                name='entity'
                onChange={handleChange.bind(null, true)}
                value={license.entity || ''}
                forceValue={!hasEntityAccess ? license.entityName : false}
                items={entities}
                useProperty='id'
                displayProperty='name'
                editing={editing && hasEntityAccess}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Assigned User:</label>
              </div>
              <EditingSelect
                name='user'
                onChange={handleChange.bind(null, false)}
                value={license.user || '-'}
                items={users}
                useProperty='id'
                displayProperty='fullName'
                editing={editing}
              />
            </li>
          </ul>
          <div className='d-flex justify-content-end mt-2'>
            <Button
              className='btn-blue mt-1 px-4 fz-12 font-weight-bold'
              onClick={() => setEditing(!editing)}
            >
              {!editing ? (
                <>
                  <IconEdit className='mr-1' /> Edit
                </>
              ) : (
                'Close'
              )}
            </Button>
            {editing && (
              <Button
                className='btn btn-blue ml-2 px-4 fz-12 font-weight-bold'
                type='submit'
                disabled={updateFetch}
              >
                Update
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LicensesView;
