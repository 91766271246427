import { lessonTypesObj } from 'app/Main/configs';
import React from 'react';
import { IconVideos } from 'shared/components/Icons';
import { bindDurationMinutes, formatDate } from 'utils/appHelpers';
import IconActivePdf from 'assets/wizard/active_pdf.svg';
import noImage from 'assets/noimage.png';

const InfoIcon = ({ name, value, className }) => (
  <p className={`mr-2 mb-0 ${className || ''}`}>
    {name}: <b className='font-weight-normal'>{value}</b>
  </p>
);

const CourseLessonsRow = ({ item }) => {
  const isPdf = item.contentType === 2;
  return (
    <div className='d-flex align-items-center course-lessons-row'>
      <div className={`image-container ${!item.imageUrl ? 'no-image' : ''}`}>
        <img src={isPdf ? IconActivePdf : item.imageUrl || noImage} alt='img' />
      </div>
      <div className='author-name mb-1'>
        <h5>
          {item.title} <span className='ml-2'>{lessonTypesObj[item.contentType]}</span>
        </h5>
        <p className='note'>{item.info}</p>
        <div className='d-flex'>
          <InfoIcon
            name='Chapters'
            value={
              <strong className='font-weight-bold'>
                {item.chapters || '-'}
                <IconVideos color='#131F6B' height='11' />
              </strong>
            }
          />
          <InfoIcon name='Date' value={formatDate(item.createdAt) || '-'} />
          <InfoIcon name='Duration' value={bindDurationMinutes(item.duration) || '-'} />
          <InfoIcon name='Nickname' value={item.nickName || '-'} />
        </div>
      </div>
    </div>
  );
};

export default CourseLessonsRow;
