import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import Button from 'shared/components/Button';
import { validateEmail } from 'utils/validators';
import { roles } from '../configs';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import moment from 'moment';

const AdminCreationForm = ({ onSubmitUser, fetch, entities }) => {
  const [editing, setEditing] = useState(true);
  const [errors, setError] = useState({
    email: '',
  });
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    userRole: 2,
    userType: 0,
    email: '',
    dateOfBirth: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempForm = { ...form };
    const tempErrors = { ...errors };
    if (name === 'email' && !validateEmail(value)) {
      tempErrors[name] = 'Please insert a valid email';
      setError(tempErrors);
    }
    errors[name] = '';
    tempForm[name] = value;
    setForm(tempForm);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setEditing(false);
    if (!editing) {
      form.userRole = Number(form.userRole);
      onSubmitUser(form);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='col-9 user-creation-form'>
      <ul className='p-0 mb-0'>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Name:</label>
          </div>
          <EditingInput
            type='text'
            name='firstName'
            value={form.firstName}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Name'
            required
            editing={editing}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Surname:</label>
          </div>
          <EditingInput
            type='text'
            name='lastName'
            value={form.lastName}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Surname'
            required
            editing={editing}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Role:</label>
          </div>
          <EditingSelect
            name='userRole'
            onChange={handleChange}
            value={form.userRole}
            items={roles}
            useProperty='value'
            displayProperty='name'
            required={true}
            editing={editing}
          />
        </li>
        {Number(form.userRole) === 4 && (
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Entity:</label>
            </div>
            <EditingSelect
              name='entityId'
              onChange={handleChange}
              value={form.entityId}
              items={entities}
              useProperty='id'
              displayProperty='name'
              required={true}
              editing={editing}
            />
          </li>
        )}
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Email:</label>
          </div>
          <div className='col p-0'>
            <EditingInput
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Email'
              required
              editing={editing}
            />
            {form.email && errors.email && <p className='m-0 mt-1 text-danger'>{errors.email}</p>}
          </div>
        </li>
        {Number(form.userRole) !== 4 && (
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Date of Birthday:</label>
            </div>
            <div className='col p-0'>
              <EditingInput
                type='date'
                name='dateOfBirth'
                value={editing ? form.dateOfBirth : moment(form.dateOfBirth).format('MM/DD/YYYY')}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter date'
                required
                editing={editing}
              />
            </div>
          </li>
        )}
      </ul>
      {!editing && (
        <p className='text-right invitation-dsc mt-2 mb-0'>
          The invitation will be sent to: {form.email}
        </p>
      )}
      <div className='d-flex justify-content-end mt-2'>
        {!editing && (
          <Button className='btn-blue mr-2 mt-1 px-4' onClick={() => setEditing(!editing)}>
            Edit
          </Button>
        )}
        <ButtonLine className='btn btn-blue px-4' type='submit' disabled={fetch}>
          {editing ? 'Next' : 'Invite'}
        </ButtonLine>
      </div>
    </form>
  );
};

export default AdminCreationForm;
