import React from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { hasAccess } from 'utils/permissionHelper';
import Loading from 'shared/components/Loading';
import placeholder from 'assets/entity.svg';

const headers = [
  {
    name: 'Name',
    className: 'col-4 d-flex justify-content-between align-items-center',
    key: 'name',
  },
  {
    name: 'Manager',
    className: 'col-3',
    key: 'manager',
  },
  {
    name: 'Members',
    className: 'col-2',
    key: 'membersCount',
  },
  {
    name: 'License Keys',
    className: 'col-2',
    key: 'licensesCount',
  },
];

const SubscriptionsLists = ({ list, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => (
            <li key={item.id} className='d-flex list-item'>
              <div className='d-flex col-4 align-items-center'>
                <img
                  src={item.imageUrl || placeholder}
                  alt='img'
                  className='user-image no-radius mr-2 bg-white'
                />
                <div>
                  <b>{item.name}</b>
                </div>
              </div>
              <div className='col-3 d-flex align-items-center'>
                {item.manager ? item.manager.fullName : '-'}
              </div>
              <div className='col-2 d-flex align-items-center'>{item.membersCount}</div>
              <div className='col-2 d-flex align-items-center'>{item.licensesCount}</div>
              {hasAccess('entity_list_view') && (
                <div className='p-2 d-flex w-100 justify-content-end'>
                  <Link className='more-btn small' to={`/entity/${item.id}`}>
                    More <IconRight />
                  </Link>
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
