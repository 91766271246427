import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';

const defaults = {
  colors: {
    backgroundColor: ['#569A58', '#699CD6', '#34609A', 'rgb(17, 50, 228)', 'rgb(96, 119, 247)'],
    borderColor: ['#569A58', '#699CD6', '#34609A', 'rgb(17, 50, 228)', 'rgb(96, 119, 247)'],
    color: [
      'rgb(255, 255, 255)',
      'rgb(255, 255, 255)',
      'rgb(255, 255, 255)',
      'rgb(255, 255, 255)',
      'rgb(255, 255, 255)',
    ],
    borderWidth: 1,
  },
  plugins: {
    datalabels: {
      color: '#FFFFFF',
      textAlign: 'center',
      font: {
        lineHeight: 1.6,
        size: 14,
      },
      formatter: value => (value ? value : ''),
    },
  },
};

const AppChart = ({
  type = 'doughnut',
  data,
  labels,
  titleText,
  styles = defaults.colors,
  plugins = defaults.plugins,
  width = 150,
  height = 150,
  id,
}) => {
  const ctx = useRef();
  const chart = useRef();

  const drawChart = () => {
    if (!data) return;

    //eslint-disable-next-line
    chart.current = new Chart(ctx.current, {
      type, // pie, doughnut, bar
      data: {
        labels: [...labels],
        datasets: [
          {
            label: '# of Data',
            data: [...data],
            ...(styles && { ...styles }),
          },
        ],
      },
      options: {
        cutoutPercentage: 60,
        title: {
          display: !!titleText,
          text: titleText,
        },
        layout: {
          padding: 0,
        },
        legend: {
          display: false,
        },
        plugins,
      },
    });
  };

  useEffect(() => {
    drawChart();
    return () => {
      chart.current && chart.current.destroy();
    };
  }, [data]);

  return (
    <canvas ref={ctx} id={id} width={width} height={height}>
      Your browser does not support the canvas element.
    </canvas>
  );
};

export default AppChart;
