import React, { useEffect, Fragment } from 'react';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAuthState } from './Auth/actions';

// Load Routes
import Auth from './Auth';
import Main from './Main';
import Loading from 'shared/components/Loading';

const App = ({ getAuthState, isAuthenticated }) => {
  useEffect(() => {
    if (isAuthenticated === null) getAuthState();
  }, [getAuthState, isAuthenticated]);

  return (
    <Fragment>
      <Router>
        {isAuthenticated !== null && (
          <Switch>
            <Route path='/' component={isAuthenticated ? Main : Auth} />
          </Switch>
        )}
        {isAuthenticated == null && <Loading />}
      </Router>
    </Fragment>
  );
};

const mapStateToProps = ({ isAuthenticated }) => ({
  isAuthenticated,
});

export default connect(
  mapStateToProps,
  { getAuthState },
)(App);
